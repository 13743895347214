import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  info:
    JSON.parse(localStorage.getItem("user")) !== null
      ? JSON.parse(localStorage.getItem("user"))
      : null,
  clockedInStatus: {
    isClockedIn: null,
  },
};

export const userSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.info = action.payload;
    },
    setClockedInStatus: (state, action) => {
      state.clockedInStatus.isClockedIn = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setClockedInStatus } = userSlice.actions;

export default userSlice.reducer;
