import React from "react";
import SideNav from "components/layout-components/SideNav";
import TopNav from "components/layout-components/TopNav";
import Loading from "components/shared-components/Loading";
import MobileNav from "components/layout-components/MobileNav";
import HeaderNav from "components/layout-components/HeaderNav";
import PageHeader from "components/layout-components/PageHeader";
import Footer from "components/layout-components/Footer";
import AppViews from "views/app-views";
import { Layout, Grid } from "antd";

import navigationConfig from "configs/NavigationConfig";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR,
} from "constants/ThemeConstant";
import utils from "utils";
import { useThemeSwitcher } from "react-css-theme-switcher";
import Confetti from "components/shared-components/Confetti";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router";
import Jobs from "views/app-views/jobs";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import PackingSlip from "views/packing-slip";
import ConnectQBO from "views/qbo-views/connect";
import Quote from "views/quote";

const { Content } = Layout;
const { useBreakpoint } = Grid;

const AppLayout = ({ navCollapsed, navType, location, direction }) => {
  const user = useSelector((state) => state.auth.user.info);
  const currentRouteInfo = utils.getRouteInfo(navigationConfig(user), location.pathname);
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes("lg");
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  const { status } = useThemeSwitcher();

  if (status === "loading") {
    return <Loading cover="page" />;
  }

  const getLayoutDirectionGutter = () => {
    if (direction === DIR_LTR) {
      return { paddingLeft: getLayoutGutter() };
    }
    if (direction === DIR_RTL) {
      return { paddingRight: getLayoutGutter() };
    }
    return { paddingLeft: getLayoutGutter() };
  };

  return (
    <>
      <Routes>
        <Route
          path={`${APP_PREFIX_PATH}/*`}
          element={
            <>
              <Layout>
                <HeaderNav isMobile={isMobile} />
                {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
                <Layout className="app-container">
                  {isNavSide && !isMobile ? <SideNav routeInfo={currentRouteInfo} /> : null}
                  <Layout className="app-layout" style={getLayoutDirectionGutter()}>
                    <div className={`app-content ${isNavTop ? "layout-top-nav" : ""}`}>
                      {/* <PageHeader
                        display={currentRouteInfo?.breadcrumb}
                        title={currentRouteInfo?.title}
                      /> */}
                      <Content>
                        <AppViews />
                      </Content>
                    </div>
                    {/* <Routes>
                      <Route path="job/*">
                        <Route path=":id" element={<PackingSlip />} />
                        <Route path="*" element={<Navigate to={`${APP_PREFIX_PATH}/jobs`} />} />
                      </Route>
                    </Routes> */}
                    <Footer />
                  </Layout>
                </Layout>
                {<MobileNav routeInfo={currentRouteInfo} />}
              </Layout>
              <Confetti />
            </>
          }
        />
        <Route path="qbo/*">
          <Route path="connect" element={<ConnectQBO />} />
          <Route path="disconnect" />
          <Route path="eula" />
          <Route path="privacy-policy" />
          <Route path="*" element={<Navigate to={`${APP_PREFIX_PATH}/jobs`} />} />
        </Route>
        <Route path="packing-slip/*">
          <Route path=":id" element={<PackingSlip />} />
          <Route path="*" element={<Navigate to={`${APP_PREFIX_PATH}/jobs`} />} />
        </Route>

        <Route path="quotes/:id/print" element={<Quote />} />

        <Route path="/*">
          <Route path="*" element={<Navigate to={`${APP_PREFIX_PATH}/jobs`} />} />
        </Route>
      </Routes>
    </>
  );
};

export default AppLayout;
