import React, { useEffect, useState } from "react";
import { Card, Row, Col, Alert, Form, Input, Button, Typography, InputNumber } from "antd";
import {
  useGetMFASetupQRCodeQuery,
  useSignoutMutation,
  useVerifyMFASetupMutation,
} from "redux/api/authApi";
import { useNavigate } from "react-router";
import { apiSlice } from "redux/slices/apiSlice";
import { useDispatch } from "react-redux";
import { setUser } from "redux/slices/user";

const { Title } = Typography;

const SetupMFA = ({ refetchAuthStatus }) => {
  const dispatch = useDispatch();

  const getMFASetupQRCode = useGetMFASetupQRCodeQuery();
  const [verifyMFASetup, verifyMFASetupResult] = useVerifyMFASetupMutation();

  const [step, setStep] = useState("1");

  const [errorMessage, setErrorMessage] = useState(null);
  const [verifySuccess, setVerifySuccess] = useState(null);

  const [signout, signoutResult] = useSignoutMutation();

  const signoutHandler = async () => {
    await signout();
  };

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const submitForm = async () => {
    try {
      const values = await form.validateFields();

      await verifyMFASetup(values.token);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (verifyMFASetupResult.isSuccess) {
      if (verifyMFASetupResult.data.verified === false) {
        setErrorMessage("Incorrect code. Please try again.");
      } else if (verifyMFASetupResult.data.verified === true) {
        localStorage.setItem("user", JSON.stringify(verifyMFASetupResult.data.user));
        dispatch(setUser(verifyMFASetupResult.data.user));

        setVerifySuccess(true);
      }
    }
  }, [verifyMFASetupResult]);

  useEffect(() => {
    if (signoutResult.isSuccess) {
      localStorage.removeItem("user");
      dispatch(apiSlice.util.resetApiState());
      navigate("/login");
      window.location.reload();
    }
  }, [signoutResult]);

  return (
    <div
      className="container d-flex flex-column justify-content-center"
      style={{ height: "100vh" }}
    >
      <Row justify="center">
        <Col style={{ maxWidth: "400px" }} span={24}>
          <Card>
            <div className="my-4">
              <div className="text-center"></div>
              <Row justify="center">
                <Col xs={24} sm={24} md={20} lg={20}>
                  {verifySuccess ? (
                    <>
                      <Alert
                        type="success"
                        showIcon
                        message="Success!"
                        description="Multi-Factor Authentication is now set up for your account."
                        size="large"
                      ></Alert>
                      <Button
                        type="primary"
                        block
                        className="mt-3"
                        size="large"
                        onClick={() => {
                          window.history.go("/dashboard/jobs");
                        }}
                        disabled={verifyMFASetupResult.isLoading}
                      >
                        Continue
                      </Button>
                    </>
                  ) : (
                    <Form layout="vertical" form={form}>
                      <>
                        {step === "1" ? (
                          <>
                            <Title strong level={1} style={{ fontFamily: "Plus Jakarta Sans" }}>
                              Step {step}.
                            </Title>
                            <p>Scan the QR Code below with your authenticator app.</p>

                            <img
                              src={getMFASetupQRCode.data}
                              width="200"
                              height="200"
                              className="d-block mx-auto mb-3"
                              draggable="false"
                            />

                            <Button
                              htmlType="button"
                              type="primary"
                              block
                              size="large"
                              onClick={() => setStep("2")}
                            >
                              Next
                            </Button>
                          </>
                        ) : (
                          <>
                            <Title strong level={1} style={{ fontFamily: "Plus Jakarta Sans" }}>
                              Step {step}.
                            </Title>
                            <p>Enter the 6-digit code displayed in your authenticator app.</p>

                            <Form.Item name="token" className="mb-2">
                              <Input
                                size="large"
                                min={100000}
                                max={999999}
                                step={1}
                                controls={false}
                                className="w-100"
                              />
                            </Form.Item>
                            {errorMessage ? (
                              <Alert
                                message={errorMessage}
                                type="error"
                                size="small"
                                className="mb-3"
                              />
                            ) : null}
                            <Form.Item>
                              <Button
                                htmlType="submit"
                                type="primary"
                                block
                                size="large"
                                className="my-2"
                                onClick={() => {
                                  setErrorMessage(null);
                                  submitForm();
                                }}
                                loading={verifyMFASetupResult.isLoading}
                              >
                                Verify
                              </Button>
                              <Button
                                htmlType="button"
                                type="ghost"
                                block
                                size="large"
                                onClick={() => {
                                  getMFASetupQRCode.refetch();
                                  setStep("1");
                                }}
                                disabled={verifyMFASetupResult.isLoading}
                              >
                                Back
                              </Button>
                            </Form.Item>
                          </>
                        )}
                      </>
                    </Form>
                  )}
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col span={24}>
          <Button
            type="link"
            onClick={signoutHandler}
            style={{ display: "block", margin: "0 auto" }}
          >
            Sign Out
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default SetupMFA;
