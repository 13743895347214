import { Form, Col, Row, Spin, Modal, Select, notification, Upload, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { closeUploadDocumentsModal } from "redux/slices/uploadDocuments";
import {
  EditOutlined,
  FileOutlined,
  MailOutlined,
  PaperClipOutlined,
  SendOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { useAddFilesMutation } from "redux/api/filesApi";

const { Option } = Select;

const UploadDocumentsModal = () => {
  const dispatch = useDispatch();

  const visible = useSelector((state) => state.app.uploadDocuments.uploadDocumentsModalVisible);

  const [form] = Form.useForm();

  const [step, setStep] = useState("docType");
  const [docType, setDocType] = useState(null);
  const [formFields, setFormFields] = useState([]);

  const [addFiles, result] = useAddFilesMutation();

  const closeModal = () => {
    dispatch(closeUploadDocumentsModal());
    setStep("docType");
    form.resetFields();
  };

  const openNotification = (type, message) => {
    notification[type]({
      message: message,
    });
  };

  const submitForm = async () => {
    try {
      const values = await form.validateFields();

      const formData = new FormData();

      for (const file of values.files.fileList) {
        formData.append(`${file.uid}`, file.originFileObj);
        formData.set("docType", docType);
      }

      await addFiles(formData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (result.isSuccess) {
      closeModal();
      openNotification("success", result.data?.message);
    }
  }, [result]);

  return (
    <Modal
      visible={visible}
      width="480px"
      centered
      title={null}
      closable={true}
      onOk={submitForm}
      onCancel={closeModal}
      maskClosable={true}
      cancelButtonProps={{ disabled: result.isLoading }}
      okButtonProps={{ disabled: result.isLoading }}
      bodyStyle={{ paddingRight: 0 }}
      footer={
        step === "uploadFiles" ? (
          <div
            style={{
              textAlign: "right",
            }}
          >
            <Button size="large" onClick={() => closeModal()} disabled={result.isLoading}>
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={() => submitForm()}
              loading={result.isLoading}
              disabled={
                formFields.length === 0 ||
                formFields.find((el) => el.name[0] === "files")?.value?.fileList.length === 0 ||
                result.isLoading
              }
              icon={<SendOutlined />}
            >
              Send Now
            </Button>
          </div>
        ) : null
      }
    >
      <div style={{ width: "calc(100% - 24px)" }}>
        <Spin spinning={result.isLoading}>
          <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            style={{ width: "calc(100% - 8px)" }}
            onFieldsChange={(_, allFields) => setFormFields(allFields)}
          >
            {step === "docType" ? (
              <>
                <h2 className="mb-4">What are you sending?</h2>
                <Col span={24}>
                  <Form.Item name="file-mail" className="mb-2">
                    <Button
                      className="ant-btn-xl"
                      block
                      icon={<MailOutlined />}
                      onClick={() => {
                        setDocType("Mail");
                        setStep("uploadFiles");
                      }}
                    >
                      Mail
                    </Button>
                  </Form.Item>
                  <Form.Item name="file-invoice" className="mb-2">
                    <Button
                      className="ant-btn-xl"
                      block
                      icon={<FileOutlined />}
                      onClick={() => {
                        setDocType("Invoice");
                        setStep("uploadFiles");
                      }}
                    >
                      Invoice
                    </Button>
                  </Form.Item>
                  <Form.Item name="file-signed-packing-slip" className="mb-2">
                    <Button
                      className="ant-btn-xl"
                      block
                      icon={<EditOutlined />}
                      onClick={() => {
                        setDocType("Signed Packing Slip");
                        setStep("uploadFiles");
                      }}
                    >
                      Signed Packing Slip
                    </Button>
                  </Form.Item>
                  <Form.Item name="file-prints" className="mb-2">
                    <Button
                      className="ant-btn-xl"
                      block
                      icon={<FileOutlined />}
                      onClick={() => {
                        setDocType("Part Prints");
                        setStep("uploadFiles");
                      }}
                    >
                      Part Prints
                    </Button>
                  </Form.Item>
                  <Form.Item name="file-other" className="mb-2">
                    <Button
                      className="ant-btn-xl ant-btn-ghost"
                      block
                      icon={<PaperClipOutlined />}
                      onClick={() => {
                        setDocType("Document");
                        setStep("uploadFiles");
                      }}
                    >
                      Something Else
                    </Button>
                  </Form.Item>
                </Col>
              </>
            ) : step === "uploadFiles" ? (
              <>
                <h1 className="mb-4">Select Files</h1>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item name="files">
                      <Upload
                        multiple
                        maxCount={10}
                        className="d-block"
                        customRequest={({ onSuccess }) => {
                          setTimeout(() => {
                            onSuccess("ok");
                          }, 0);
                        }}
                      >
                        <Button
                          className="ant-btn-xl"
                          type="primary"
                          block
                          icon={<UploadOutlined />}
                        >
                          Select Files
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  {/* <pre>{JSON.stringify(formFields, null, 3)}</pre> */}
                </Row>
              </>
            ) : null}

            {/* <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="shop"
                    label="Shop to subcontract to"
                    rules={[
                      {
                        required: true,
                        message: "Please select one or more shops",
                      },
                    ]}
                  >
                    <Select placeholder="Please select a shop to subcontract to">
                      {shops?.data
                        ?.filter((shop) => shop._id !== job.shop._id)
                        .map((shop) => {
                          return (
                            <Option key={shop._id} value={shop._id}>
                              {`${shop.name} (${shop.companyCode})`}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row> */}
          </Form>
          {/* <pre>{JSON.stringify(lineItems, null, 3)}</pre> */}
        </Spin>
      </div>
    </Modal>
  );
};

export default UploadDocumentsModal;
