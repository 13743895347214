import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  Col,
  Form,
  List,
  Modal,
  Row,
  Select,
  Typography,
  Button,
  Tag,
} from "antd";
import { useGetAllJobsQuery, useStartJobMutation } from "redux/api/jobsApi";
import {
  useGetAllProjectsQuery,
  useStartProjectMutation,
} from "redux/api/projectsApi";
import { useGetClockedInStatusQuery } from "redux/api/authApi";

// import { useWebSocket } from "WebSocketProvider";

export default function SwitchJobs({
  modalVisible,
  setModalVisible,
  latitude,
  longitude,
  userSelectedShop,
}) {
  const user = useSelector((state) => state.auth.user.info);

  // const { message, sendMessage } = useWebSocket();

  const clockedInStatusQuery = useGetClockedInStatusQuery(
    {},
    { pollingInterval: 1000 }
  );

  const [form] = Form.useForm();

  const jobs = useGetAllJobsQuery(
    { shop: userSelectedShop, archived: "false", pageSize: 100 },
    { skip: !userSelectedShop }
  );
  const projects = useGetAllProjectsQuery();

  const [startJob, startJobResult] = useStartJobMutation();
  const [startProject, startProjectResult] = useStartProjectMutation();

  const [yourProjects, setYourProjects] = useState([]);
  const [jobsAssignedToYou, setJobsAssignedToYou] = useState([]);

  const startJobHandler = async (e, job) => {
    e.preventDefault();
    await startJob({ id: job._id, body: { latitude, longitude } });
  };

  const startProjectHandler = async (e, project) => {
    e.preventDefault();
    await startProject({ id: project._id, body: { latitude, longitude } });
  };

  const headerText =
    clockedInStatusQuery?.data?.activeJobsAndProjects?.length > 0
      ? `What's next, ${user.firstName}?`
      : `What are you working on today, ${user.firstName}?`;

  useEffect(() => {
    if (modalVisible) {
      // When modal is opened, refetch jobs list
      jobs.refetch();
      projects.refetch();
    }
  }, [modalVisible]);

  useEffect(() => {
    if (jobs?.data) {
      setJobsAssignedToYou(
        jobs?.data?.data?.filter((job) =>
          job?.lineItems?.find(
            (lineItem) =>
              lineItem?.assignedTo?.includes(user._id) && job.progress < 100
          )
        )
      );
    }
  }, [jobs]);

  useEffect(() => {
    if (projects?.data) {
      setYourProjects(
        projects?.data?.filter((project) =>
          project.collaborators.find((el) => el._id === user._id)
        )
      );
    }
  }, [projects]);

  useEffect(() => {
    if (startJobResult.isSuccess) {
      setModalVisible(false);
      clockedInStatusQuery.refetch();
    }
  }, [startJobResult]);

  useEffect(() => {
    if (startProjectResult.isSuccess) {
      setModalVisible(false);
      clockedInStatusQuery.refetch();
    }
  }, [startProjectResult]);

  return (
    <>
      <Modal
        // title="Start Job"
        visible={modalVisible}
        onCancel={() => {
          setModalVisible(false);
        }}
        maskClosable={false}
        // confirmLoading={result.isLoading}
        // onOk={submitForm}
        width="800px"
        bodyStyle={{ padding: 0 }}
        centered
        footer={null}
      >
        <h2
          className="px-4 py-3"
          style={{
            display: "block",
            fontSize: "1.75rem",
            marginBottom: "0",
          }}
        >
          {headerText}
        </h2>
        <Scrollbars style={{ width: "100%", height: "calc(100vh - 200px)" }}>
          <Form form={form} layout="vertical" requiredMark={false}>
            <Row
              gutter={16}
              style={{ width: "calc(100% - 6px)", paddingLeft: "24px" }}
            >
              <Col span={24}>
                {jobsAssignedToYou.length > 0 ? (
                  <>
                    <div
                      style={{
                        display: "block",
                        fontWeight: "500",
                        fontSize: "1.25rem",
                        padding: "2rem 0 0.25rem",
                        color: "#444",
                        borderBottom: "2px solid #444",
                      }}
                    >
                      Jobs assigned to you
                    </div>
                    <List
                      dataSource={jobsAssignedToYou}
                      renderItem={(job) => (
                        <List.Item
                          style={{
                            border: "1px solid rgb(197 222 251)",
                            borderRadius: "0.625rem",
                            marginTop: "0.5rem",
                            backgroundColor: "#f4faff",
                            marginBottom: "0.5rem",
                          }}
                          actions={[
                            <Button
                              type="primary"
                              onClick={(e) => startJobHandler(e, job)}
                              disabled={
                                clockedInStatusQuery?.data
                                  ?.activeJobsAndProjects[0]?.job?._id ===
                                job._id
                              }
                            >
                              START
                            </Button>,
                          ]}
                        >
                          <List.Item.Meta
                            title={
                              <div
                                className="d-flex"
                                style={{ alignItems: "center" }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Plus Jakarta Sans",
                                    fontSize: "1.25rem",
                                    color: "#0083ff",
                                  }}
                                >
                                  {user.role.canReadJobsFromAllShops
                                    ? job?.jobNumber
                                    : job?.jobNumber?.slice(-3)}
                                </span>
                                {clockedInStatusQuery?.data
                                  ?.activeJobsAndProjects[0]?.job?._id ===
                                job._id ? (
                                  <Tag className="ml-2" color="success">
                                    Active Job
                                  </Tag>
                                ) : null}
                              </div>
                            }
                            description={
                              <span style={{ fontSize: ".9rem" }}>
                                {job?.customer?.customerCode
                                  ? `${job.customer.customerCode} | ${job?.description}`
                                  : job?.description}
                              </span>
                            }
                            extra
                          />
                        </List.Item>
                      )}
                    />
                  </>
                ) : null}

                {yourProjects.length > 0 ? (
                  <>
                    <div
                      style={{
                        display: "block",
                        fontWeight: "500",
                        fontSize: "1.25rem",
                        padding: "2rem 0 0.25rem",
                        color: "#444",
                        borderBottom: "2px solid #444",
                      }}
                    >
                      Your Projects
                    </div>

                    <List
                      dataSource={yourProjects}
                      renderItem={(project) => (
                        <List.Item
                          style={{
                            border: "1px solid #e7ccff",
                            borderRadius: "0.625rem",
                            marginTop: "0.5rem",
                            backgroundColor: "#fbf5ff",
                            marginBottom: "0.5rem",
                          }}
                          actions={[
                            <Button
                              type="primary"
                              className="ant-btn-purple"
                              onClick={(e) => startProjectHandler(e, project)}
                              disabled={
                                clockedInStatusQuery?.data
                                  ?.activeJobsAndProjects[0]?.project?._id ===
                                project._id
                              }
                            >
                              START
                            </Button>,
                          ]}
                        >
                          <List.Item.Meta
                            style={{ textAlign: "left" }}
                            title={
                              <div
                                className="d-flex"
                                style={{ alignItems: "center" }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Plus Jakarta Sans",
                                    fontSize: "1.25rem",
                                    color: "#9125e4",
                                    margin: 0,
                                  }}
                                >
                                  {project?.name}{" "}
                                </span>
                                {clockedInStatusQuery?.data
                                  ?.activeJobsAndProjects[0]?.project?._id ===
                                project._id ? (
                                  <Tag className="ml-2" color="success">
                                    Active Project
                                  </Tag>
                                ) : null}
                              </div>
                            }
                            description={
                              <span
                                style={{ fontSize: ".9rem" }}
                              >{`${project.description}`}</span>
                            }
                            extra
                          />
                        </List.Item>
                      )}
                    />
                  </>
                ) : null}

                <div
                  style={{
                    display: "block",
                    fontWeight: "500",
                    fontSize: "1.25rem",
                    padding: "2rem 0 0.25rem",
                    color: "#444",
                    borderBottom: "2px solid #444",
                  }}
                >
                  {jobsAssignedToYou.length > 0 ? "Other Jobs" : "Jobs"}
                </div>
                <List
                  dataSource={jobs?.data?.data?.filter(
                    (job) =>
                      !job.lineItems.find((lineItem) =>
                        lineItem.assignedTo?.includes(user._id)
                      ) && job.progress < 100
                  )}
                  renderItem={(job) => (
                    <List.Item
                      style={{
                        border: "1px solid rgb(197 222 251)",
                        borderRadius: "0.625rem",
                        marginTop: "0.5rem",
                        backgroundColor: "#f4faff",
                        marginBottom: "0.5rem",
                      }}
                      actions={[
                        <Button
                          type="primary"
                          onClick={(e) => startJobHandler(e, job)}
                          disabled={
                            clockedInStatusQuery?.data?.activeJobsAndProjects[0]
                              ?.job?._id === job._id
                          }
                        >
                          START
                        </Button>,
                      ]}
                    >
                      <List.Item.Meta
                        title={
                          <div
                            className="d-flex"
                            style={{ alignItems: "center" }}
                          >
                            <span
                              style={{
                                fontFamily: "Plus Jakarta Sans",
                                fontSize: "1.25rem",
                                color: "#0083ff",
                                margin: 0,
                              }}
                            >
                              {user.role.canReadJobsFromAllShops
                                ? job?.jobNumber
                                : job?.jobNumber?.slice(-3)}
                            </span>
                            {clockedInStatusQuery?.data
                              ?.activeJobsAndProjects[0]?.job?._id ===
                            job._id ? (
                              <Tag className="ml-2" color="success">
                                Active Job
                              </Tag>
                            ) : null}
                          </div>
                        }
                        description={
                          <span style={{ fontSize: ".9rem" }}>
                            {job?.customer?.customerCode
                              ? `${job.customer.customerCode} | ${job?.description}`
                              : job?.description}
                          </span>
                        }
                        extra
                      />
                    </List.Item>
                  )}
                />
              </Col>
            </Row>
          </Form>
        </Scrollbars>
      </Modal>
    </>
  );
}
