import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import CustomerRejectedParts from "./customerRejectedParts";
import InternalNonConformingParts from "./internalNonConformingParts";
import LinkageKits from "./linkage-kits";
import LinkageKitsOrders from "./linkage-kits/orders";
import LinkageKitsCustomerMessages from "./linkage-kits/contact";
import ManufactureRates from "./linkage-kits/manufactureRates";
import LinkageKitsAbandonedCarts from "./linkage-kits/abandonedCarts";

const Home = lazy(() => import("./home"));
const Jobs = lazy(() => import("./jobs"));
const Job = lazy(() => import("./jobs/job"));
const AddJob = lazy(() => import("./jobs/add"));
const Customers = lazy(() => import("./customers"));
const Customer = lazy(() => import("./customers/customer"));
const AddCustomer = lazy(() => import("./customers/add"));
const Items = lazy(() => import("./items"));
const Shops = lazy(() => import("./shops"));
const Users = lazy(() => import("./users"));
const Tasks = lazy(() => import("./tasks/scrumboard"));
const Roles = lazy(() => import("./roles"));
const Machines = lazy(() => import("./machines"));
const Maintenances = lazy(() => import("./maintenances"));
const MaterialRequests = lazy(() => import("./materialRequests"));
const Timesheet = lazy(() => import(`./timesheet/v2`));
const TimesheetApprovals = lazy(() => import("./timesheet/approvals"));
const Projects = lazy(() => import("./projects"));
const Products = lazy(() => import("./products"));
const Schedules = lazy(() => import("./schedules"));
const Files = lazy(() => import("./files"));
const Reports = lazy(() => import("./reports"));
const Quotes = lazy(() => import("./quotes2"));
const AddQuote = lazy(() => import("./quotes2/add"));
const Quote = lazy(() => import("./quotes2/start"));
const AuctionItems = lazy(() => import("./auction-items"));
const Admin = lazy(() => import("./admin"));

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Routes>
        <Route path={`jobs/*`}>
          <Route path={`add`} element={<AddJob />} />
          <Route path={`:id`} element={<Job />} />
          <Route path={`*`} element={<Jobs />} />
        </Route>
        <Route path={`customers/*`}>
          <Route path={`add`} element={<AddCustomer />} />
          <Route path={`:id`} element={<Customer />} />
          <Route path={`*`} element={<Customers />} />
        </Route>
        <Route path={`quotes/*`}>
          <Route path={`add`} element={<AddQuote />} />
          <Route path={`:id`} element={<Quote />} />
          <Route path={`*`} element={<Quotes />} />
        </Route>
        <Route path={`files`} element={<Files />} />
        <Route path={`projects`} element={<Projects />} />
        <Route path={`items`} element={<Items />} />
        <Route path={`products`} element={<Products />} />
        <Route path={`shops`} element={<Shops />} />
        <Route path={`users`} element={<Users />} />
        <Route path={`reports`} element={<Reports />} />
        <Route path={`roles`} element={<Roles />} />
        <Route path={`annual-llcs`} element={<Customers />} />
        <Route path={`tasks`} element={<Tasks />} />
        <Route path={`machines`} element={<Machines />} />
        <Route path={`maintenance`} element={<Maintenances />} />
        <Route path={`material-requests`} element={<MaterialRequests />} />
        <Route path={`timesheets/`} element={<Timesheet />} />
        <Route path={`timesheets/approvals`} element={<TimesheetApprovals />} />
        <Route path={`schedules`} element={<Schedules />} />
        <Route path={`auction-items`} element={<AuctionItems />} />
        <Route
          path={`internal-ncps`}
          element={<InternalNonConformingParts />}
        />
        <Route
          path={`customer-rejected-parts`}
          element={<CustomerRejectedParts />}
        />
        <Route path={`linkage-kits/*`}>
          <Route path={`build`} element={<LinkageKits />} />
          <Route path={`orders`} element={<LinkageKitsOrders />} />
          <Route
            path={`abandoned-carts`}
            element={<LinkageKitsAbandonedCarts />}
          />
          <Route path={`manufacture-rates`} element={<ManufactureRates />} />
          <Route
            path={`customer-messages`}
            element={<LinkageKitsCustomerMessages />}
          />
        </Route>
        <Route path={`admin`} element={<Admin />} />
        {/* <Route path={`/setup-mfa`} element={<SetupMFA />} /> */}
        {/* <Route path={`/*`} element={<Navigate to={`${APP_PREFIX_PATH}/jobs`} />} /> */}
      </Routes>
      <Routes>
        <Route path={`/`} element={<Home />} />
      </Routes>
    </Suspense>
  );
};

export default React.memo(AppViews);
