import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addMaintenanceDrawerVisible: false,
  updateMaintenanceDrawerVisible: false,
};

export const maintenancesSlice = createSlice({
  name: "maintenances",
  initialState,
  reducers: {
    showAddMaintenanceDrawer: (state) => {
      state.addMaintenanceDrawerVisible = true;
    },
    closeAddMaintenanceDrawer: (state) => {
      state.addMaintenanceDrawerVisible = false;
    },
    showUpdateMaintenanceDrawer: (state) => {
      state.updateMaintenanceDrawerVisible = true;
    },
    closeUpdateMaintenanceDrawer: (state) => {
      state.updateMaintenanceDrawerVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showAddMaintenanceDrawer,
  closeAddMaintenanceDrawer,
  showUpdateMaintenanceDrawer,
  closeUpdateMaintenanceDrawer,
} = maintenancesSlice.actions;

export default maintenancesSlice.reducer;
