import React, { useEffect, useState } from "react";
import { Card, Col, Divider, Form, Row, Select } from "antd";
import {
  useGetActuatorBrandsQuery,
  useGetActuatorModelsBySeriesQuery,
  useGetActuatorOptionsQuery,
  useGetActuatorSeriesByBrandQuery,
  useGetValveBrandsQuery,
  useGetValveOptionsQuery,
  useGetValveSeriesByBrandQuery,
  useGetValveSizesBySeriesQuery,
} from "redux/api/linkageKitsApi";
import PageTitle from "components/shared-components/PageTitle";
import { isEmpty, startCase } from "lodash";

const { Option } = Select;

const LinkageKits = () => {
  const [selectedValveBrand, setSelectedValveBrand] = useState(null);
  const [selectedValveSeries, setSelectedValveSeries] = useState(null);
  const [selectedActuatorBrand, setSelectedActuatorBrand] = useState(null);
  const [selectedActuatorSeries, setSelectedActuatorSeries] = useState(null);
  const [selectedValveSize, setSelectedValveSize] = useState(null);
  const [selectedActuatorModel, setSelectedActuatorModel] = useState(null);

  const [selectedValve, setSelectedValve] = useState(null);
  const [selectedActuator, setSelectedActuator] = useState(null);

  const getValveBrands = useGetValveBrandsQuery();
  const getActuatorBrands = useGetActuatorBrandsQuery();

  const getValveSeriesByBrand = useGetValveSeriesByBrandQuery(
    {
      brand: selectedValveBrand,
    },
    { skip: !selectedValveBrand, refetchOnMountOrArgChange: true }
  );

  const getActuatorSeriesByBrand = useGetActuatorSeriesByBrandQuery(
    {
      brand: selectedActuatorBrand,
    },
    { skip: !selectedActuatorBrand, refetchOnMountOrArgChange: true }
  );

  const getValveSizesBySeries = useGetValveSizesBySeriesQuery(
    {
      brand: selectedValveBrand,
      series: selectedValveSeries,
    },
    { skip: !selectedValveBrand || !selectedValveSeries, refetchOnMountOrArgChange: true }
  );

  const getActuatorModelsBySeries = useGetActuatorModelsBySeriesQuery(
    {
      brand: selectedActuatorBrand,
      series: selectedActuatorSeries,
    },
    { skip: !selectedActuatorBrand || !selectedActuatorSeries, refetchOnMountOrArgChange: true }
  );

  const getValveOptions = useGetValveOptionsQuery(
    {
      brand: selectedValveBrand,
      series: selectedValveSeries,
      size: selectedValveSize,
    },
    {
      skip: !selectedValveBrand || !selectedValveSeries || !selectedValveSize,
      refetchOnMountOrArgChange: true,
    }
  );

  const getActuatorOptions = useGetActuatorOptionsQuery(
    {
      brand: selectedActuatorBrand,
      series: selectedActuatorSeries,
      model: selectedActuatorModel,
    },
    {
      skip: !selectedActuatorBrand || !selectedActuatorSeries || !selectedActuatorModel,
      refetchOnMountOrArgChange: true,
    }
  );

  function handleChangeValveBrand(brand) {
    setSelectedValveBrand(brand);
    setSelectedValveSeries(null);
    setSelectedValveSize(null);
  }

  function handleChangeActuatorBrand(brand) {
    setSelectedActuatorBrand(brand);
    setSelectedActuatorSeries(null);
    setSelectedActuatorModel(null);
  }

  function handleChangeValveSeries(series) {
    setSelectedValveSeries(series);
    setSelectedActuatorModel(null);
  }

  function handleChangeActuatorSeries(series) {
    setSelectedActuatorSeries(series);
    setSelectedActuatorModel(null);
  }

  function handleChangeValveSize(size) {
    setSelectedValveSize(size);
  }

  function handleChangeActuatorModel(model) {
    setSelectedActuatorModel(model);
  }

  return (
    <div className="container">
      <PageTitle title="Build Your Linkage Kit" />
      <Form layout="vertical" style={{ width: "100%" }}>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Card title="Select Valve">
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <Form.Item label="Valve Brand">
                    <Select
                      style={{ width: "100%" }}
                      value={selectedValveBrand}
                      onChange={handleChangeValveBrand}
                    >
                      {getValveBrands?.data?.map((brand) => {
                        return (
                          <Option key={brand} value={brand}>
                            {brand}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Valve Series">
                    <Select
                      style={{ width: "100%" }}
                      value={selectedValveSeries}
                      onChange={handleChangeValveSeries}
                      disabled={!selectedValveBrand}
                    >
                      {getValveSeriesByBrand?.data?.map((series) => {
                        return (
                          <Option key={series} value={series}>
                            {series}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Valve Sizes">
                    <Select
                      style={{ width: "100%" }}
                      value={selectedValveSize}
                      onChange={handleChangeValveSize}
                      disabled={!selectedValveBrand || !selectedValveSeries}
                    >
                      {getValveSizesBySeries?.data?.map((series) => {
                        return (
                          <Option key={series} value={series}>
                            {series}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                {getValveOptions?.data?.length > 0
                  ? getValveOptions?.data?.map((option) => {
                      const options = option?.values?.map((x) => ({
                        value: x,
                        label: x,
                      }));

                      const label =
                        option.name === "asmeClass" ? "ASME Class" : startCase(option.name);

                      return (
                        <React.Fragment key={option.name}>
                          <Col span={24}>
                            <Form.Item label={label}>
                              <Select options={options} />
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      );
                    })
                  : null}
              </Row>
            </Card>
          </Col>
          <Col span={6}>
            <Card title="Select Actuator">
              <Row gutter={[16, 0]}>
                <Col span={24}>
                  <Form.Item label="Actuator Brand">
                    <Select
                      style={{ width: "100%" }}
                      value={selectedActuatorBrand}
                      onChange={handleChangeActuatorBrand}
                    >
                      {getActuatorBrands?.data?.map((brand) => {
                        return (
                          <Option key={brand} value={brand}>
                            {brand}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Actuator Series">
                    <Select
                      style={{ width: "100%" }}
                      value={selectedActuatorSeries}
                      onChange={handleChangeActuatorSeries}
                      disabled={!selectedActuatorBrand}
                    >
                      {getActuatorSeriesByBrand?.data?.map((series) => {
                        return (
                          <Option key={series} value={series}>
                            {series}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Actuator Models">
                    <Select
                      style={{ width: "100%" }}
                      value={selectedActuatorModel}
                      onChange={handleChangeActuatorModel}
                      disabled={!selectedActuatorBrand || !selectedActuatorSeries}
                    >
                      {getActuatorModelsBySeries?.data?.map((series) => {
                        return (
                          <Option key={series} value={series}>
                            {series}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                {getActuatorOptions?.data?.length > 0
                  ? getActuatorOptions?.data?.map((option) => {
                      const options = option?.values?.map((x) => ({
                        value: x,
                        label: x,
                      }));

                      const label =
                        option.name === "asmeClass" ? "ASME Class" : startCase(option.name);

                      return (
                        <React.Fragment key={option.name}>
                          <Col span={24}>
                            <Form.Item label={label}>
                              <Select options={options} />
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      );
                    })
                  : null}
              </Row>
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Select Options"></Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default LinkageKits;
