import { combineReducers } from "redux";
import auth from "./auth";
import theme from "./theme";
import app from "./app";
import { apiSlice } from "./apiSlice";

const reducers = combineReducers({
  theme: theme,
  auth: auth,
  app: app,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export default reducers;
