import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Modal,
  Row,
  Spin,
  Tooltip,
  Typography,
  Button,
  Alert,
  Tag,
} from "antd";
import {
  useGetClockedInStatusQuery,
  useVerifyGeoLocationMutation,
} from "redux/api/authApi";
import {
  ArrowRightOutlined,
  BorderOutlined,
  CaretRightOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CoffeeOutlined,
  CustomerServiceOutlined,
  HourglassOutlined,
  LoadingOutlined,
  StopFilled,
  StopOutlined,
  SwapOutlined,
} from "@ant-design/icons";
import moment from "moment";
import SwitchJobs from "./SwitchJobs";
import {
  useClockInMutation,
  useClockOutMutation,
  useStartBreakMutation,
  useStartLunchMutation,
  useStopBreakMutation,
  useStopLunchMutation,
} from "redux/api/employeeHoursApi";
import { setClockedInStatus } from "redux/slices/user";
import { useStopJobHoursMutation } from "redux/api/jobHoursApi";
// import { useWebSocket } from "WebSocketProvider";

const { Text } = Typography;

export default function ClockInOut({ children }) {
  const user = useSelector((state) => state.auth.user.info);
  const dispatch = useDispatch();

  // const { message, sendMessage } = useWebSocket();

  const clockedInStatusQuery = useGetClockedInStatusQuery(
    {},
    { pollingInterval: 1000 }
  );

  const [verifyGeoLocation, verifyGeoLocationResult] =
    useVerifyGeoLocationMutation();

  const [stopJobHours, stopJobHoursResult] = useStopJobHoursMutation();

  const [clockIn, clockInResult] = useClockInMutation();
  const [clockOut, clockOutResult] = useClockOutMutation();
  const [startLunch, startLunchResult] = useStartLunchMutation();
  const [stopLunch, stopLunchResult] = useStopLunchMutation();
  const [startBreak, startBreakResult] = useStartBreakMutation();
  const [stopBreak, stopBreakResult] = useStopBreakMutation();

  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [clockInLocationsModalVisible, setClockInLocationsModalVisible] =
    useState(false);
  const [confirmClockOutModalVisible, setConfirmClockOutModalVisible] =
    useState(false);
  const [locationServicesEnabled, setLocationServicesEnabled] = useState(null);

  const [timeOnBreak, setTimeOnBreak] = useState(null);
  const [timeOnLunch, setTimeOnLunch] = useState(null);
  const [timeOnJob, setTimeOnJob] = useState(null);

  const onClockInHandler = async (e, userSelectedShop) => {
    e.preventDefault();
    await clockIn({
      latitude: latitude,
      longitude: longitude,
      userSelectedShop: userSelectedShop,
    });
    setClockInLocationsModalVisible(false);
  };

  const onClockOutHandler = async (e) => {
    e.preventDefault();
    await clockOut({ latitude: latitude, longitude: longitude });
  };

  const startLunchHandler = async (e) => {
    e.preventDefault();
    await startLunch({ latitude: latitude, longitude: longitude });
  };

  const stopLunchHandler = async (e) => {
    e.preventDefault();
    await stopLunch({ latitude: latitude, longitude: longitude });
    // setTimeOnLunch({ formatted: null, seconds: null });
  };

  const startBreakHandler = async (e) => {
    e.preventDefault();
    await startBreak({ latitude: latitude, longitude: longitude });
  };

  const stopBreakHandler = async (e) => {
    e.preventDefault();
    await stopBreak({ latitude: latitude, longitude: longitude });
    // setTimeOnBreak({ formatted: null, seconds: null });
  };

  const stopJobHoursHandler = async (e) => {
    e.preventDefault();
    await stopJobHours({
      id: clockedInStatusQuery?.data?.activeJobsAndProjects[0]._id,
      body: {
        latitude: latitude,
        longitude: longitude,
      },
    });
  };

  const geoLocation = async (latitude, longitude) => {
    await verifyGeoLocation({ latitude, longitude });
  };

  const getLocationPermission = () => {
    navigator.geolocation.getCurrentPosition((success, error) => {
      if (error) {
        setLocationServicesEnabled(false);
      }
      setLocationServicesEnabled(true);
    });
  };

  const loading =
    clockOutResult.isLoading ||
    stopJobHoursResult.isLoading ||
    startLunchResult.isLoading ||
    stopLunchResult.isLoading ||
    startBreakResult.isLoading ||
    stopBreakResult.isLoading ||
    (!startBreakResult.isUninitialized && !startBreakResult.isSuccess) ||
    (!stopBreakResult.isUninitialized && !stopBreakResult.isSuccess);

  // Not compatible with iOS or Safari :(
  // useEffect(() => {
  //   navigator.permissions.query({ name: "geolocation" }).then(function (result) {
  //     if (result.state === "granted") {
  //       setLocationServicesEnabled(true);
  //     } else if (result.state === "denied") {
  //       setLocationServicesEnabled(false);
  //     }
  //   });
  // }, [navigator.permissions.query({ name: "geolocation" })]);

  useEffect(() => {
    getLocationPermission();
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);

      geoLocation(position.coords.latitude, position.coords.longitude);
    });
  }, [locationServicesEnabled]);

  useEffect(() => {
    if (clockedInStatusQuery?.data?.isClockedIn) {
      // Set globally-accessible state value
      dispatch(setClockedInStatus(true));
    } else {
      // Set globally-accessible state value
      dispatch(setClockedInStatus(false));
    }
  }, [clockedInStatusQuery?.data]);

  useEffect(() => {
    if (clockedInStatusQuery?.data?.isClockedIn) {
      const interval = setInterval(() => {
        if (clockedInStatusQuery?.data?.isOnLunch) {
          let ms = moment().diff(
            clockedInStatusQuery?.data?.currentShift.hours
              ?.at(-1)
              ?.lunch?.at(-1)?.startDetails?.timestamp,
            "hh:mm:ss"
          );
          let d = moment.duration(ms);

          setTimeOnLunch({
            formatted: `${String(d._data.hours).padStart(2, "0")}:${String(
              d._data.minutes
            ).padStart(2, "0")}:${String(d._data.seconds).padStart(2, "0")}`,
            seconds: Number((d._milliseconds / 1000).toFixed(0)),
          });
        }

        if (clockedInStatusQuery?.data?.isOnBreak) {
          let ms = moment().diff(
            clockedInStatusQuery?.data?.currentShift.hours
              ?.at(-1)
              ?.breaks?.at(-1)?.startDetails?.timestamp,
            "hh:mm:ss"
          );
          let d = moment.duration(ms);

          setTimeOnBreak({
            formatted: `${String(d._data.hours).padStart(2, "0")}:${String(
              d._data.minutes
            ).padStart(2, "0")}:${String(d._data.seconds).padStart(2, "0")}`,
            seconds: Number((d._milliseconds / 1000).toFixed(0)),
          });
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [clockedInStatusQuery?.data]);

  useEffect(() => {
    if (clockedInStatusQuery?.data?.activeJobsAndProjects?.length > 0) {
      const interval = setInterval(() => {
        let ms = moment().diff(
          clockedInStatusQuery?.data?.activeJobsAndProjects[0]?.startTime,
          "hh:mm:ss"
        );
        let d = moment.duration(ms);

        setTimeOnJob(
          `${String(d._data.hours).padStart(2, "0")}:${String(
            d._data.minutes
          ).padStart(2, "0")}:${String(d._data.seconds).padStart(2, "0")}`
        );
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [clockedInStatusQuery?.data]);

  useEffect(() => {
    if (clockInResult.isSuccess) {
      setModalVisible(true);
      clockedInStatusQuery.refetch();
    }
  }, [clockInResult]);

  useEffect(() => {
    if (clockOutResult.isSuccess) {
      setConfirmClockOutModalVisible(false);
      clockedInStatusQuery.refetch();
    }
  }, [clockOutResult]);

  useEffect(() => {
    if (stopJobHoursResult.isSuccess) {
      setTimeOnJob(0);
      clockedInStatusQuery.refetch();
    }
  }, [stopJobHoursResult]);

  useEffect(() => {
    if (startBreakResult.isSuccess) {
      clockedInStatusQuery.refetch();
    }
  }, [startBreakResult]);

  useEffect(() => {
    if (stopBreakResult.isSuccess) {
      setTimeOnBreak(null);
      clockedInStatusQuery.refetch();
    }
  }, [stopBreakResult]);

  useEffect(() => {
    if (startLunchResult.isSuccess) {
      clockedInStatusQuery.refetch();
    }
  }, [startLunchResult]);

  useEffect(() => {
    if (stopLunchResult.isSuccess) {
      setTimeOnLunch(null);
      clockedInStatusQuery.refetch();
    }
  }, [stopLunchResult]);

  return (
    <div>
      <Modal
        width={400}
        centered={true}
        visible={clockInLocationsModalVisible}
        footer={null}
        closable={true}
        onCancel={() => {
          setClockInLocationsModalVisible(false);
        }}
      >
        <>
          <h1 className="mb-3">Choose Shop</h1>

          <Button
            block
            type="primary"
            className="mt-3"
            size="large"
            onClick={(e) => {
              onClockInHandler(e, user.shop._id);
            }}
          >
            {user.shop.name}
          </Button>

          {/* <pre>{JSON.stringify(user?.additionalShops, null, 3)}</pre> */}

          {user?.additionalShops?.map((x) => {
            return (
              <Button
                block
                type="secondary"
                className="mt-3"
                size="large"
                onClick={(e) => {
                  onClockInHandler(e, x?.shop?._id);
                }}
              >
                {x?.shop?.name}
              </Button>
            );
          })}
        </>
      </Modal>
      <Modal
        width={400}
        visible={confirmClockOutModalVisible}
        footer={null}
        closable={false}
        centered={true}
      >
        <>
          <h1 className="mb-3">Clock out?</h1>

          <Row gutter={6}>
            <Col span={24} sm={12}>
              <Button
                block
                type="secondary"
                className="mt-2"
                size="large"
                onClick={() => {
                  setConfirmClockOutModalVisible(false);
                }}
              >
                No
              </Button>
            </Col>
            <Col span={24} sm={12}>
              <Button
                block
                type="primary"
                className="mt-2"
                size="large"
                onClick={(e) => {
                  onClockOutHandler(e);
                }}
              >
                Yes
              </Button>
            </Col>
          </Row>
        </>
      </Modal>
      {locationServicesEnabled ? (
        <>
          <SwitchJobs
            modalVisible={modalVisible}
            setModalVisible={setModalVisible}
            latitude={latitude}
            longitude={longitude}
            userSelectedShop={clockedInStatusQuery?.data?.userSelectedShop?._id}
          />
          {verifyGeoLocationResult.isLoading ||
          verifyGeoLocationResult.isUninitialized ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "24px",
              }}
            >
              <Spin indicator={<LoadingOutlined />} size="large" />
            </div>
          ) : !verifyGeoLocationResult.isError &&
            verifyGeoLocationResult.isSuccess ? (
            <div
              className="container px-2 pt-3 pb-2"
              style={{ borderBottom: "1px solid rgb(230, 235, 241)" }}
            >
              <Row className="d-flex justify-content-center">
                <Col span={24}>
                  <>
                    <span
                      className="mb-3"
                      style={{
                        textAlign: "center",
                        display: "block",
                        width: "100%",
                        fontSize: "1.1rem",
                        lineHeight: "2rem",
                        fontWeight: "600",
                      }}
                    >
                      {` Hi, ${user.firstName}!`}
                    </span>

                    {clockedInStatusQuery?.data?.userSelectedShop ? (
                      <div className="d-flex flex-column text-center mb-3">
                        <div
                          className="text-center"
                          style={{ fontSize: "0.75rem" }}
                        >
                          CLOCKED IN AT
                        </div>
                        <Text className="font-weight-bold font-size-md">
                          {clockedInStatusQuery?.data?.userSelectedShop?.name}
                        </Text>
                      </div>
                    ) : null}
                  </>
                  {!verifyGeoLocationResult?.data?.canClockIn &&
                  !clockedInStatusQuery?.data?.isClockedIn ? (
                    <>
                      <Text
                        strong
                        type="danger"
                        className="text-center d-block pb-3"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="feather feather-map-pin mr-1"
                        >
                          <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                          <circle cx="12" cy="10" r="3"></circle>
                        </svg>
                        <span
                          style={{
                            display: "inline-block",
                            fontSize: "1rem",
                            fontWeight: "400",
                          }}
                        >
                          Not On Site
                        </span>
                      </Text>
                      <Alert
                        type="warning"
                        description="You may clock in once you are at work"
                        className="mb-0 w-100"
                      />
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={6}>
                {clockedInStatusQuery?.data?.isClockedIn ? (
                  <>
                    {clockedInStatusQuery?.data?.isOnLunch ? (
                      <>
                        <Col span={24} className="mb-2">
                          <span
                            style={{ fontSize: "1rem", fontWeight: "500" }}
                            className="d-block w-100 text-center"
                          >
                            <CoffeeOutlined /> On Lunch
                          </span>
                          <Text
                            type={
                              timeOnLunch?.seconds <= 1800
                                ? "success"
                                : "danger"
                            }
                            style={{ fontSize: "1.75rem", fontWeight: "700" }}
                            className="d-block w-100 text-center"
                          >
                            {/* <HourglassOutlined />  */}
                            {timeOnLunch?.formatted}
                          </Text>
                        </Col>
                        <Col span={24} className="mb-2">
                          <Button
                            block
                            type="primary"
                            size="large"
                            className="ant-btn-warning"
                            icon={<StopOutlined />}
                            onClick={(e) => stopLunchHandler(e)}
                            disabled={
                              !clockedInStatusQuery?.data?.isClockedIn ||
                              loading
                            }
                          >
                            END LUNCH
                          </Button>
                        </Col>
                      </>
                    ) : clockedInStatusQuery?.data?.isOnBreak ? (
                      <>
                        <Col span={24} className="mb-2">
                          <span
                            style={{ fontSize: "1rem", fontWeight: "500" }}
                            className="d-block w-100 text-center"
                          >
                            <CustomerServiceOutlined /> On Break
                          </span>
                          <Text
                            type={
                              timeOnBreak?.seconds <= 900 ? "success" : "danger"
                            }
                            style={{ fontSize: "1.75rem", fontWeight: "700" }}
                            className="d-block w-100 text-center"
                          >
                            {/* <HourglassOutlined />  */}
                            {timeOnBreak?.formatted}
                          </Text>
                        </Col>
                        <Col span={24} className="mb-2">
                          <Button
                            block
                            type="primary"
                            size="large"
                            className="ant-btn-warning"
                            icon={<StopOutlined />}
                            onClick={(e) => stopBreakHandler(e)}
                            disabled={
                              !clockedInStatusQuery?.data?.isClockedIn ||
                              loading
                            }
                          >
                            END BREAK
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <>
                        {clockedInStatusQuery?.data?.activeJobsAndProjects
                          ?.length > 0 &&
                        clockedInStatusQuery?.data?.activeJobsAndProjects[0]
                          ?.job ? (
                          <>
                            <Col span={24} className="mb-3">
                              <div
                                className="text-center mb-1"
                                style={{ fontSize: "0.75rem" }}
                              >
                                CURRENT JOB
                              </div>
                              <div
                                mark
                                className="d-block text-center"
                                style={{ fontSize: "1rem", fontWeight: "600" }}
                              >
                                <Tag
                                  color="blue"
                                  style={{
                                    padding: "6px",
                                    marginRight: "0",
                                    fontSize: "1.5rem",
                                    fontFamily: "Plus Jakarta Sans",
                                    whiteSpace: "break-spaces",
                                  }}
                                >
                                  {user.role.canReadJobsFromAllShops
                                    ? clockedInStatusQuery?.data
                                        ?.activeJobsAndProjects[0]?.job
                                        .jobNumber
                                    : clockedInStatusQuery?.data?.activeJobsAndProjects[0]?.job.jobNumber.slice(
                                        -3
                                      )}
                                </Tag>
                              </div>
                            </Col>
                            <Col span={24} className="mb-2">
                              <div
                                className="d-block text-center"
                                style={{ fontSize: "0.75rem" }}
                              >
                                TIME ON JOB
                              </div>
                              <div
                                className="d-block text-center"
                                style={{
                                  fontSize: "1.5rem",
                                  fontWeight: "600",
                                }}
                              >
                                {timeOnJob || <LoadingOutlined />}
                              </div>
                            </Col>

                            <Col span={24} className="mb-2">
                              <Button
                                block
                                type="primary"
                                size="large"
                                className="ant-btn-warning"
                                icon={<StopOutlined />}
                                onClick={(e) => {
                                  stopJobHoursHandler(e);
                                }}
                                disabled={
                                  !clockedInStatusQuery?.data?.isClockedIn ||
                                  loading
                                }
                              >
                                STOP JOB
                              </Button>
                            </Col>

                            <Col span={24} className="mb-2">
                              <Button
                                block
                                type="primary"
                                size="large"
                                icon={<SwapOutlined />}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setModalVisible(true);
                                }}
                                disabled={
                                  !clockedInStatusQuery?.data?.isClockedIn ||
                                  loading
                                }
                              >
                                SWITCH JOBS
                              </Button>
                            </Col>
                          </>
                        ) : clockedInStatusQuery?.data?.activeJobsAndProjects
                            ?.length > 0 &&
                          clockedInStatusQuery?.data?.activeJobsAndProjects[0]
                            ?.project ? (
                          <>
                            <Col span={24} className="mb-3">
                              <div
                                className="text-center mb-1"
                                style={{ fontSize: "0.75rem" }}
                              >
                                CURRENT PROJECT
                              </div>
                              <div
                                mark
                                className="d-block text-center"
                                style={{ fontSize: "1rem", fontWeight: "600" }}
                              >
                                <Tag
                                  color="purple"
                                  style={{
                                    padding: "6px",
                                    marginRight: "0",
                                    fontSize: "1rem",
                                    whiteSpace: "break-spaces",
                                  }}
                                >
                                  {
                                    clockedInStatusQuery?.data
                                      ?.activeJobsAndProjects[0]?.project.name
                                  }
                                </Tag>
                              </div>
                            </Col>
                            <Col span={24} className="mb-2">
                              <div
                                className="d-block text-center"
                                style={{ fontSize: "0.75rem" }}
                              >
                                TIME ON PROJECT
                              </div>
                              <div
                                className="d-block text-center"
                                style={{
                                  fontSize: "1.5rem",
                                  fontWeight: "600",
                                }}
                              >
                                {timeOnJob || <LoadingOutlined />}
                              </div>
                            </Col>

                            <Col span={24} className="mb-2">
                              <Button
                                block
                                type="primary"
                                size="large"
                                className="ant-btn-warning"
                                icon={<StopOutlined />}
                                onClick={(e) => {
                                  stopJobHoursHandler(e);
                                }}
                                disabled={
                                  !clockedInStatusQuery?.data?.isClockedIn ||
                                  loading
                                }
                              >
                                STOP PROJECT
                              </Button>
                            </Col>

                            <Col span={24} className="mb-2">
                              <Button
                                block
                                type="primary"
                                size="large"
                                icon={<SwapOutlined />}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setModalVisible(true);
                                }}
                                disabled={
                                  !clockedInStatusQuery?.data?.isClockedIn ||
                                  loading
                                }
                              >
                                SWITCH
                              </Button>
                            </Col>
                          </>
                        ) : (
                          <>
                            <Col span={24} className="mb-2">
                              <Button
                                block
                                type="primary"
                                size="large"
                                icon={<CaretRightOutlined />}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setModalVisible(true);
                                }}
                                disabled={
                                  !clockedInStatusQuery?.data?.isClockedIn ||
                                  loading
                                }
                              >
                                START ON JOB
                              </Button>
                            </Col>
                          </>
                        )}
                        <Col span={12} className="mb-2">
                          <Button
                            block
                            type="ghost"
                            onClick={(e) => startBreakHandler(e)}
                            disabled={
                              !clockedInStatusQuery?.data?.isClockedIn ||
                              loading
                            }
                          >
                            BREAK
                          </Button>
                        </Col>
                        <Col span={12} className="mb-2">
                          <Button
                            block
                            type="ghost"
                            onClick={(e) => startLunchHandler(e)}
                            disabled={
                              !clockedInStatusQuery?.data?.isClockedIn ||
                              loading
                            }
                          >
                            LUNCH
                          </Button>
                        </Col>
                      </>
                    )}

                    <Col span={24}>
                      <Button
                        block
                        type="primary"
                        className="ant-btn-danger"
                        size="large"
                        icon={<ClockCircleOutlined />}
                        onClick={() => {
                          setConfirmClockOutModalVisible(true);
                        }}
                        //  style={{ height: "70px" }}
                        loading={clockOutResult.isLoading}
                        disabled={loading}
                      >
                        CLOCK OUT
                        {/* <span
                      style={{
                        display: "block",
                        fontFamily: "Roboto Mono",
                        fontWeight: "500",
                      }}
                    >
                      {clockedInTime || <LoadingOutlined />}
                    </span> */}
                      </Button>
                    </Col>
                  </>
                ) : verifyGeoLocationResult?.data?.canClockIn &&
                  !clockedInStatusQuery?.data?.isClockedIn ? (
                  <>
                    <Button
                      block
                      className="ant-btn-success"
                      size="large"
                      icon={<ClockCircleOutlined />}
                      onClick={(e) => {
                        if (
                          user.additionalShops &&
                          user.additionalShops.length > 0
                        ) {
                          setClockInLocationsModalVisible(true);
                        } else {
                          onClockInHandler(e, user.shop._id);
                        }
                      }}
                      loading={clockInResult.isLoading}
                      disabled={loading}
                    >
                      CLOCK IN
                    </Button>

                    {/* {hasClockedOutToday ? (
                      <Alert
                        type="warning"
                        showIcon
                        clockedInStatusQuery?.data?="You have already clocked out today"
                        className="mb-0 mt-2 w-100"
                      />
                    ) : null} */}
                  </>
                ) : null}
              </Row>
            </div>
          ) : (
            <div className="p-2">
              <Alert
                message="This is embarrassing"
                description="The website could not aquire your location"
                type="error"
                className="w-100"
              />
            </div>
          )}
        </>
      ) : (
        <div className="p-2">
          <Alert
            message="Oh Snap!"
            description={
              <>
                Please enable location services to clock in. <br />
                <Button
                  size="small"
                  type="ghost"
                  className="mt-2 w-100"
                  onClick={getLocationPermission}
                >
                  Try Again
                </Button>
              </>
            }
            type="warning"
          />
        </div>
      )}
    </div>
  );
}
