import {
  DashboardOutlined,
  FileTextOutlined,
  HomeOutlined,
  ContactsOutlined,
  UserOutlined,
  BarsOutlined,
  CheckSquareOutlined,
  ClearOutlined,
  ControlOutlined,
  ToolOutlined,
  ClockCircleOutlined,
  ExperimentOutlined,
  CalendarOutlined,
  PaperClipOutlined,
  ShoppingOutlined,
  LineChartOutlined,
  HourglassOutlined,
  SettingOutlined,
  DollarCircleOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import { Badge } from "antd";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const navigationConfig = (user) => {
  return [
    {
      key: "dashboard",
      path: `${APP_PREFIX_PATH}/dashboards`,
      title: "sidenav.dashboard",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: [
        {
          key: "quotes",
          path: `${APP_PREFIX_PATH}/quotes`,
          // title: <Badge title="beta" color="geekblue">Quotes</Badge>,
          title: "Quotes",
          icon: DollarCircleOutlined,
          breadcrumb: false,
          submenu: [],
          hidden:
            !user.role.canCreateQuotesForOwnShop && !user.role.canCreateQuotesForAllShops
              ? true
              : false,
        },
        {
          key: "jobs",
          path: `${APP_PREFIX_PATH}/jobs`,
          title: "Jobs",
          icon: FileTextOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "projects",
          path: `${APP_PREFIX_PATH}/projects`,
          title: "Projects",
          icon: ExperimentOutlined,
          breadcrumb: false,
          submenu: [],
          hidden: !user.role.canCreateProjectsForOwnShop,
        },
        {
          key: "items",
          path: `${APP_PREFIX_PATH}/items`,
          title: "Items To Order",
          icon: CheckSquareOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "materialRequests",
          path: `${APP_PREFIX_PATH}/material-requests`,
          title: "Material Requests",
          icon: CheckSquareOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "tasks",
          path: `${APP_PREFIX_PATH}/tasks`,
          title: "Tasks",
          icon: ClearOutlined,
          breadcrumb: false,
          submenu: [],
          hidden: true,
        },
        {
          key: "customers",
          path: `${APP_PREFIX_PATH}/customers`,
          title: "Customers",
          icon: ContactsOutlined,
          breadcrumb: false,
          submenu: [],
          hidden:
            !user.role.canCreateCustomersForOwnShop || !user.role.canCreateCustomersForAllShops
              ? true
              : false,
        },
        {
          key: "linkage-kits",
          path: `${APP_PREFIX_PATH}/linkage-kits`,
          title: "Linkage Kits",
          icon: AppstoreAddOutlined,
          breadcrumb: false,
          submenu: [
            {
              key: "linkage-kits-orders",
              path: `${APP_PREFIX_PATH}/linkage-kits/orders`,
              title: "Orders",
              breadcrumb: false,
              submenu: [],
            },
            {
              key: "linkage-kits-abandoned-carts",
              path: `${APP_PREFIX_PATH}/linkage-kits/abandoned-carts`,
              title: "Abandoned Carts",
              breadcrumb: false,
              submenu: [],
            },
            {
              key: "linkage-kits-inbox",
              path: `${APP_PREFIX_PATH}/linkage-kits/customer-messages`,
              title: "Inbox",
              breadcrumb: false,
              submenu: [],
            },
            {
              key: "linkage-kits-builder",
              path: `${APP_PREFIX_PATH}/linkage-kits/build`,
              title: "Kit Builder",
              breadcrumb: false,
              submenu: [],
              hidden: user.email !== "natewright95@gmail.com",
            },
            {
              key: "manufacture-rates",
              path: `${APP_PREFIX_PATH}/linkage-kits/manufacture-rates`,
              title: "Manufacture Rates",
              breadcrumb: false,
              submenu: [],
            },
          ],
        },
        {
          key: "products",
          path: `${APP_PREFIX_PATH}/products`,
          title: "Products",
          icon: ShoppingOutlined,
          breadcrumb: false,
          submenu: [],
          hidden:
            !!user.role.canReadProductsFromOwnShop || !!user.role.canReadProductsFromAllShops
              ? false
              : true,
        },
        {
          key: "files",
          path: `${APP_PREFIX_PATH}/files`,
          title: "Files",
          icon: PaperClipOutlined,
          breadcrumb: false,
          submenu: [],
          hidden: !!user.role.canReadFilesSentToOffice ? false : true,
        },
        {
          key: "shops",
          path: `${APP_PREFIX_PATH}/shops`,
          title: "Shops",
          icon: HomeOutlined,
          breadcrumb: false,
          submenu: [],
          hidden: !!user.role.canCreateShops ? false : true,
        },
        {
          key: "machines",
          path: `${APP_PREFIX_PATH}/machines`,
          title: "Machines",
          icon: ControlOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "maintenance",
          path: `${APP_PREFIX_PATH}/maintenance`,
          title: "Maintenance",
          icon: ToolOutlined,
          breadcrumb: false,
          submenu: [],
        },
        user.role.canApproveTimesheetsForSubordinates || user.role.canApproveTimesheetsForAllUsers
          ? {
              key: "timesheets",
              title: "Timesheets",
              icon: ClockCircleOutlined,
              breadcrumb: false,
              submenu: [
                {
                  key: "myTimesheets",
                  path: `${APP_PREFIX_PATH}/timesheets`,
                  title: "My Timesheets",
                  breadcrumb: false,
                  hidden: user.isSharedAccount,
                },
                {
                  key: "approvals",
                  path: `${APP_PREFIX_PATH}/timesheets/approvals`,
                  title: "Approvals",
                  breadcrumb: false,
                  hidden: user.isSharedAccount,
                },
              ],
              hidden: user.isSharedAccount,
            }
          : {
              key: "timesheets",
              path: `${APP_PREFIX_PATH}/timesheets`,
              title: "Timesheets",
              icon: ClockCircleOutlined,
              breadcrumb: false,
              submenu: [],
              hidden: user.isSharedAccount,
            },
        {
          key: "users",
          path: `${APP_PREFIX_PATH}/users`,
          title: "Users",
          icon: UserOutlined,
          breadcrumb: false,
          submenu: [],
          hidden:
            !!user.role.canCreateUsersForOwnShop || !!user.role.canCreateUsersForAllShops
              ? false
              : true,
        },
        {
          key: "reports",
          path: `${APP_PREFIX_PATH}/reports`,
          title: "Reports",
          icon: LineChartOutlined,
          breadcrumb: false,
          submenu: [],
          // hidden:
          //   !!user.role.canCreateUsersForOwnShop || !!user.role.canCreateUsersForAllShops
          //     ? false
          //     : true,
        },
        {
          key: "auction-items",
          path: `${APP_PREFIX_PATH}/auction-items`,
          title: "Auction Items",
          icon: HourglassOutlined,
          breadcrumb: false,
          submenu: [],
          hidden: !!user.role.canReadAuctionItems ? false : true,
        },
        {
          key: "schedules",
          path: `${APP_PREFIX_PATH}/schedules`,
          title: "Schedules",
          icon: CalendarOutlined,
          breadcrumb: false,
          submenu: [],
          hidden: !!user.role.canReadSchedules ? false : true,
        },
        {
          key: "roles",
          path: `${APP_PREFIX_PATH}/roles`,
          title: "Roles",
          icon: BarsOutlined,
          breadcrumb: false,
          submenu: [],
          hidden: !!user.role.canCreateRoles ? false : true,
        },
        {
          key: "Admin",
          path: `${APP_PREFIX_PATH}/admin`,
          title: "Admin",
          icon: SettingOutlined,
          breadcrumb: false,
          submenu: [],
          hidden: user.role.name !== "Super Admin",
        },
      ].filter((item) => !item.hidden),
    },
  ];
};

// const navigationConfig = [...dashBoardNavTree()];

export default navigationConfig;
