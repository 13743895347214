import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Badge, Avatar, List, Button, Typography } from "antd";
import moment from "moment";
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import {
  useClearNotificationsMutation,
  useGetAllNotificationsQuery,
  useMarkNotificationAsReadMutation,
} from "redux/api/notificationsApi";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { showUpdateJobDrawer } from "redux/slices/jobs";

const { Text } = Typography;

const getIcon = (icon) => {
  switch (icon) {
    case "mail":
      return <MailOutlined />;
    case "alert":
      return <WarningOutlined />;
    case "check":
      return <CheckCircleOutlined />;
    default:
      return <MailOutlined />;
  }
};

export const NavNotification = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getAllNotifications = useGetAllNotificationsQuery({}, { pollingInterval: 10_000 });

  const clearNotificationsHandler = async (e) => {
    e.preventDefault();
    await clearNotifications();
  };

  const [clearNotifications, clearNotificationsResult] = useClearNotificationsMutation();
  const [markNotificationAsRead, markNotificationAsReadResult] =
    useMarkNotificationAsReadMutation();

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const getNotificationBody = (list) => {
    // Reverse Array to show newest notifications first
    const reversed = [...list].reverse();

    return reversed.length > 0 ? (
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={reversed}
        renderItem={(notification) => (
          <List.Item
            className="list-clickable"
            onClick={async () => {
              const readNotification = () => {
                setVisible(false);
                navigate(`/dashboard/jobs/${notification.job._id}`);
                dispatch(showUpdateJobDrawer());
              };

              setData([
                ...data.map((x) => {
                  return x._id === notification._id ? { ...x, read: true } : x;
                }),
              ]);
              await markNotificationAsRead({ id: notification._id });

              return notification.job ? readNotification() : null;
            }}
          >
            <table className="w-100">
              <td>
                <Text strong={notification?.read !== true} className="text-dark">
                  {notification.title}
                </Text>
                <br />
                <span className="text-gray-light">{notification.description}</span>
              </td>

              <td className="d-block ml-auto text-right">
                {moment().format("MM/DD/YY") ===
                moment(notification.timestamp).format("MM/DD/YY") ? (
                  <small style={{ whiteSpace: "nowrap" }}>{`${moment(notification.timestamp).format(
                    "hh:mm A"
                  )}`}</small>
                ) : (
                  <small>{moment(notification.timestamp).format("MM/DD")}</small>
                )}
              </td>
            </table>
          </List.Item>
        )}
      />
    ) : (
      <div className="empty-notification">
        <img
          src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
          alt="empty"
        />
        <p className="mt-3">You have viewed all notifications</p>
      </div>
    );
  };

  useEffect(() => {
    if (getAllNotifications.isSuccess) {
      setData(getAllNotifications.data?.notifications);
    }
  }, [getAllNotifications]);

  useEffect(() => {
    if (markNotificationAsReadResult.isSuccess) {
      getAllNotifications.refetch();
    }
  }, [markNotificationAsReadResult]);

  useEffect(() => {
    if (clearNotificationsResult.isSuccess) {
      setData([]);
      getAllNotifications.refetch();
    }
  }, [clearNotificationsResult]);

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notifications</h4>
        <Button
          className="text-primary"
          type="link"
          onClick={(e) => clearNotificationsHandler(e)}
          size="small"
        >
          Clear
        </Button>
      </div>
      <div className="nav-notification-body">{getNotificationBody(data)}</div>
      {data.length > 0 ? (
        <div className="nav-notification-footer">
          {/* <a className="d-block" href="#/">
            View all
          </a> */}
        </div>
      ) : null}
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={data.filter((x) => x.read !== true).length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
