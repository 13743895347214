import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addItemDrawerVisible: false,
  updateItemDrawerVisible: false,
};

export const itemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    showAddItemDrawer: (state) => {
      state.addItemDrawerVisible = true;
    },
    closeAddItemDrawer: (state) => {
      state.addItemDrawerVisible = false;
    },
    showUpdateItemDrawer: (state) => {
      state.updateItemDrawerVisible = true;
    },
    closeUpdateItemDrawer: (state) => {
      state.updateItemDrawerVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showAddItemDrawer,
  closeAddItemDrawer,
  showUpdateItemDrawer,
  closeUpdateItemDrawer,
} = itemsSlice.actions;

export default itemsSlice.reducer;
