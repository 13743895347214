import "../node_modules/antd-button-color/dist/css/style.css";
import "animate.css";

import React, { lazy, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";

import Views from "./views";
import { useAuthStatusQuery } from "redux/api/authApi";
import { setUser } from "redux/slices/user";
import SetupMFA from "views/app-views/setup-mfa";
import Loading from "components/shared-components/Loading";

const Login = lazy(() => import("views/auth-views/authentication/login"));

function CheckAuth({ children }) {
  const user = useSelector((state) => state.auth.user.info);
  const location = useLocation();
  const dispatch = useDispatch();

  const theme = useSelector((state) => state.theme);
  const { locale, direction, navCollapsed, navType } = theme;

  const query = useAuthStatusQuery();

  useEffect(() => {
    if (query.isSuccess && !query.isError && query.data._id) {
      // Set updated user data to state and local storage
      localStorage.setItem("user", JSON.stringify(query.data));
      dispatch(setUser(query.data));
    }
  }, [query]);

  return query.isUninitialized || query.isLoading || query.isFetching ? (
    <Loading cover="page" />
  ) : (
    <Routes>
      {query.isSuccess && !query.isError ? (
        <>
          {(query.data.MFAEnabled === true &&
            query.data.MFAVerified === true) ||
          query.data.MFAEnabled === false ? (
            <>
              <Route
                path="*"
                element={
                  <Views
                    locale={locale}
                    direction={direction}
                    location={location}
                    navCollapsed={navCollapsed}
                    navType={navType}
                  />
                }
              />
            </>
          ) : (query.data.MFAEnabled === true &&
              query.data.MFAVerified !== true) ||
            query.data.message === "You must setup MFA to continue" ? (
            <>
              <Route
                path="/setup-mfa"
                element={<SetupMFA refetchAuthStatus={query.refetch} />}
              />
              <Route path="/*" element={<Navigate to="/setup-mfa" />} />
            </>
          ) : null}
        </>
      ) : (
        <>
          <Route
            path="/login"
            element={
              <div className="auth-container">
                <Login refetch={query.refetch} />
              </div>
            }
          />
          <Route path="/*" element={<Navigate path="*" to="/login" />} />
        </>
      )}
    </Routes>
  );
}

export default function App() {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="*" element={<CheckAuth />} />
      </Routes>
    </Suspense>
  );
}
