import chroma from "chroma-js";
import moment from "moment";

class Utils {
  /**
   * Get first character from first & last sentences of a username
   * @param {String} name - Username
   * @return {String} 2 characters string
   */
  static getNameInitial(name) {
    let initials = name.match(/\b\w/g) || [];
    return initials.join("").toUpperCase();
  }

  /**
   * Get current path related object from Navigation Tree
   * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
   * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
   * @return {Object} object that contained the path string
   */
  static getRouteInfo(navTree, path) {
    if (navTree.path === path) {
      return navTree;
    }
    let route;
    for (let p in navTree) {
      if (navTree.hasOwnProperty(p) && typeof navTree[p] === "object") {
        route = this.getRouteInfo(navTree[p], path);
        if (route) {
          return route;
        }
      }
    }
    return route;
  }

  /**
   * Get accessible color contrast
   * @param {String} hex - Hex color code e.g '#3e82f7'
   * @return {String} 'dark' or 'light'
   */
  static getColorContrast(hex) {
    if (!hex) {
      return "dark";
    }
    const threshold = 130;
    const hRed = hexToR(hex);
    const hGreen = hexToG(hex);
    const hBlue = hexToB(hex);
    function hexToR(h) {
      return parseInt(cutHex(h).substring(0, 2), 16);
    }
    function hexToG(h) {
      return parseInt(cutHex(h).substring(2, 4), 16);
    }
    function hexToB(h) {
      return parseInt(cutHex(h).substring(4, 6), 16);
    }
    function cutHex(h) {
      return h.charAt(0) === "#" ? h.substring(1, 7) : h;
    }
    const cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
    if (cBrightness > threshold) {
      return "dark";
    } else {
      return "light";
    }
  }

  /**
   * Darken or lighten a hex color
   * @param {String} color - Hex color code e.g '#3e82f7'
   * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
   * @return {String} Darken or lighten color
   */
  static shadeColor(color, percent) {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);
    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);
    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    const RR =
      R.toString(16).length === 1 ? `0${R.toString(16)}` : R.toString(16);
    const GG =
      G.toString(16).length === 1 ? `0${G.toString(16)}` : G.toString(16);
    const BB =
      B.toString(16).length === 1 ? `0${B.toString(16)}` : B.toString(16);
    return `#${RR}${GG}${BB}`;
  }

  /**
   * Convert RGBA to HEX
   * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
   * @return {String} HEX color
   */
  static rgbaToHex(rgba) {
    const trim = (str) => str.replace(/^\s+|\s+$/gm, "");
    const inParts = rgba.substring(rgba.indexOf("(")).split(","),
      r = parseInt(trim(inParts[0].substring(1)), 10),
      g = parseInt(trim(inParts[1]), 10),
      b = parseInt(trim(inParts[2]), 10),
      a = parseFloat(
        trim(inParts[3].substring(0, inParts[3].length - 1))
      ).toFixed(2);
    const outParts = [
      r.toString(16),
      g.toString(16),
      b.toString(16),
      Math.round(a * 255)
        .toString(16)
        .substring(0, 2),
    ];

    outParts.forEach(function (part, i) {
      if (part.length === 1) {
        outParts[i] = "0" + part;
      }
    });
    return `#${outParts.join("")}`;
  }

  /**
   * Returns either a positive or negative
   * @param {Number} number - number value
   * @param {any} positive - value that return when positive
   * @param {any} negative - value that return when negative
   * @return {any} positive or negative value based on param
   */
  static getSignNum(number, positive, negative) {
    if (number > 0) {
      return positive;
    }
    if (number < 0) {
      return negative;
    }
    return null;
  }

  /**
   * Returns either ascending or descending value
   * @param {Object} a - antd Table sorter param a
   * @param {Object} b - antd Table sorter param b
   * @param {String} key - object key for compare
   * @return {any} a value minus b value
   */
  static antdTableSorter(a, b, key) {
    if (typeof a[key] === "number" && typeof b[key] === "number") {
      return a[key] - b[key];
    }

    if (typeof a[key] === "string" && typeof b[key] === "string") {
      a = a[key].toLowerCase();
      b = b[key].toLowerCase();
      return a > b ? -1 : b > a ? 1 : 0;
    }
    return;
  }

  /**
   * Filter array of object
   * @param {Array} list - array of objects that need to filter
   * @param {String} key - object key target
   * @param {any} value  - value that excluded from filter
   * @return {Array} a value minus b value
   */
  static filterArray(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] === value);
    }
    return data;
  }

  /**
   * Remove object from array by value
   * @param {Array} list - array of objects
   * @param {String} key - object key target
   * @param {any} value  - target value
   * @return {Array} Array that removed target object
   */
  static deleteArrayRow(list, key, value) {
    let data = list;
    if (list) {
      data = list.filter((item) => item[key] !== value);
    }
    return data;
  }

  /**
   * Wild card search on all property of the object
   * @param {Number | String} input - any value to search
   * @param {Array} list - array for search
   * @return {Array} array of object contained keyword
   */
  static wildCardSearch(list, input) {
    const searchText = (item) => {
      for (let key in item) {
        if (item[key] == null) {
          continue;
        }
        if (
          item[key]
            .toString()
            .toUpperCase()
            .indexOf(input.toString().toUpperCase()) !== -1
        ) {
          return true;
        }
      }
    };
    list = list.filter((value) => searchText(value));
    return list;
  }

  /**
   * Get Breakpoint
   * @param {Object} screens - Grid.useBreakpoint() from antd
   * @return {Array} array of breakpoint size
   */
  static getBreakPoint(screens) {
    let breakpoints = [];
    for (const key in screens) {
      if (screens.hasOwnProperty(key)) {
        const element = screens[key];
        if (element) {
          breakpoints.push(key);
        }
      }
    }
    return breakpoints;
  }

  static getDueDateTagColor(dueDate, statusPercentage) {
    const divisions = 14;
    // chroma scale ["red", "green"]
    const scale = chroma
      .scale(["#ff6a6a", "#1fe76f"])
      .mode("lch")
      .colors(divisions);
    const today = moment();

    const daysTillDue = Number(moment(dueDate).diff(today, "days"));

    let bgColor = "#ffffff";

    if (statusPercentage < 100) {
      if (daysTillDue <= divisions) {
        bgColor = scale[daysTillDue];
      }
      if (daysTillDue >= divisions) {
        bgColor = scale[scale.length - 1];
      }
      if (daysTillDue <= 0) {
        bgColor = scale[0];
      }
    } else {
      if (daysTillDue <= 0) {
        bgColor = "#ffffff";
      }
    }

    const getTextColor = (bgColor) => {
      let text;

      let white = chroma("#ffffff");
      let black = chroma("#000000");

      let contrastWithWhite = chroma.contrast(bgColor, white);
      let contrastWithBlack = chroma.contrast(bgColor, black);

      if (contrastWithWhite >= 6) {
        text = white;
      } else if (contrastWithBlack >= 6) {
        text = black;
      }

      return text;
    };

    return {
      background: bgColor,
      color: getTextColor(bgColor),
      textShadow: "1px 1px 0 rgba(255,255,255,0.2)",
    };
    // return {
    //   background: "#fff",
    //   color: "000",
    //   textShadow: "1px 1px 0 rgba(255,255,255,0.2)",
    // };
  }

  static formattedJobNumber(user, jobNumber) {
    return user?.role.canReadJobsFromAllShops ? jobNumber : jobNumber.slice(-3);
  }

  static price(value) {
    return `$${Number(value).toFixed(2)}`;
  }

  static minutesToObject(value) {
    const minutes = Number(value) % 60;
    const hours = (Number(value) - minutes) / 60;

    return {
      hours: hours,
      minutes: minutes,
    };
  }

  static objectToMinutes(object) {
    return object.hours * 60 + object.minutes;
  }

  static minutesToHours(value) {
    const minutes = Number(value) % 60;
    const hours = (Number(value) - minutes) / 60;

    return `${hours.toFixed(0).padStart(2, "0")}:${minutes
      .toFixed(0)
      .padStart(2, "0")}`;
  }

  static minutesToHoursFormat2(value) {
    const minutes = Number(value) % 60;
    const hours = (Number(value) - minutes) / 60;

    return hours.toFixed(0) > 0
      ? `${hours.toFixed(0)}h ${minutes.toFixed(0)}m`
      : `${minutes.toFixed(0)}m`;
  }

  static unformatHoursMinutes(value) {
    const hours = Number(value.split(" ")[0]);
    const minutes = Number(value.split(" ")[2]);

    return String(hours * 60 + minutes);
  }

  static hoursAndMinutesFormatLimiter(val) {
    const limit = (val, max) => {
      if (val.length === 1 && val[0] > max[0]) {
        val = "0" + val;
      }

      if (val.length === 2) {
        if (Number(val) === 0) {
          val = "01";

          //this can happen when user paste number
        } else if (val > max) {
          val = max;
        }
      }

      return val;
    };

    let hours = limit(val.substring(0, 2), "23");
    let minutes = limit(val.substring(2, 4), "59");

    return `${hours} hr ${minutes} min`;
  }

  static minutes() {
    let minutes = [];
    let i = 0;

    while (i < 60) {
      minutes.push(i);
      i += 5;
    }

    return minutes;
  }

  static hours() {
    let hours = [];
    let i = 0;

    while (i < 24) {
      hours.push(i);
      i++;
    }

    return hours;
  }

  static formatTimesheet(ts) {
    let timesheet = [];
    let timeOnJobs = [];
    let untrackedTime = [];
    let clockIn = [];
    let clockOut = [];
    let shops = [];
    let payrollTime = [];

    for (const day of ts.days) {
      for (const job of day.jobs) {
        let jobExists = timesheet.findIndex(
          (x) => x?.job?._id === job?.job?._id
        );

        if (jobExists !== -1) {
          timesheet[jobExists].time.push({
            _id: job.ref,
            date: day.date,
            dayOfWeek: day.dayOfWeek,
            shop: {
              _id: job?.job?.shop?._id,
              name: job?.job?.shop?.name,
            },
            minutes: job.minutes,
            isUserEdited: job.isUserEdited,
          });
        } else {
          timesheet.push({
            job: {
              _id: job?.job?._id,
              jobNumber: job?.job?.jobNumber,
            },
            time: [
              {
                _id: job.ref,
                date: day.date,
                dayOfWeek: day.dayOfWeek,
                shop: {
                  _id: job?.job?.shop?._id,
                  name: job?.job?.shop?.name,
                },
                minutes: job.minutes,
                isUserEdited: job.isUserEdited,
              },
            ],
          });
        }
      }
      for (const project of day.projects) {
        let projectExists = timesheet.findIndex(
          (x) => x?.project?._id === project?.project?._id
        );

        if (projectExists !== -1) {
          timesheet[projectExists].time.push({
            _id: project.ref,
            date: day.date,
            dayOfWeek: day.dayOfWeek,
            shop: {
              _id: project?.project?.shop?._id,
              name: project?.project?.shop?.name,
            },
            minutes: project.minutes,
            isUserEdited: project.isUserEdited,
          });
        } else {
          timesheet.push({
            project: {
              _id: project?.project?._id,
              name: project.project.name,
            },
            time: [
              {
                _id: project.ref,
                date: day.date,
                dayOfWeek: day.dayOfWeek,
                shop: {
                  _id: project?.project?.shop?._id,
                  name: project?.project?.shop?.name,
                },
                minutes: project.minutes,
                isUserEdited: project.isUserEdited,
              },
            ],
          });
        }
      }

      timeOnJobs.push({
        _id: day._id,
        date: day.date,
        dayOfWeek: day.dayOfWeek,
        minutes: String(
          Number(
            day.jobs?.reduce((acc, curr) => acc + Number(curr.minutes), 0)
          ) +
            Number(
              day.projects?.reduce((acc, curr) => acc + Number(curr.minutes), 0)
            )
        ),
      });

      untrackedTime.push({
        _id: day._id,
        date: day.date,
        dayOfWeek: day.dayOfWeek,
        minutes: String(
          Number(day.payroll.minutes) -
            Number(day.payroll.minutesOnJobsAndProjects)
        ),
      });

      clockIn.push({
        _id: day._id,
        date: day.date,
        dayOfWeek: day.dayOfWeek,
        time: day.payroll.firstClockIn.timestamp,
      });

      if (day.payroll.finalClockOut.timestamp) {
        clockOut.push({
          _id: day._id,
          date: day.date,
          dayOfWeek: day.dayOfWeek,
          time: day.payroll.finalClockOut.timestamp,
        });
      }

      if (day?.payroll?.shops && day.payroll.shops.length > 0) {
        for (const shop of day.payroll.shops) {
          let index = shops.findIndex((x) => {
            return x?._id === shop?.shop?._id;
          });

          if (index !== -1) {
            shops[index]?.payrollTime?.push({
              _id: day._id,
              date: day.date,
              dayOfWeek: day.dayOfWeek,
              minutes: shop.minutes,
              isUserEdited: day.payroll.isUserEdited,
            });
          }
        }
      }

      payrollTime.push({
        _id: day._id,
        date: day.date,
        dayOfWeek: day.dayOfWeek,
        minutes: day.payroll.minutes,
        isUserEdited: day.payroll.isUserEdited,
      });
    }

    timesheet.push({ timeOnJobs: timeOnJobs });
    timesheet.push({ untrackedTime: untrackedTime });
    timesheet.push({ clockIn: clockIn });
    timesheet.push({ clockOut: clockOut });

    for (const day of ts.days) {
      if (day?.payroll?.shops && day.payroll.shops.length > 0) {
        day.payroll.shops.map((shop) => {
          let index = timesheet.findIndex(
            (x) => x.shopPayrollTime?.shop?._id === shop?.shop?._id
          );

          let values = {
            _id: day._id,
            date: day.date,
            dayOfWeek: day.dayOfWeek,
            minutes: shop?.minutes,
            isUserEdited: shop.isUserEdited,
          };

          if (index === -1) {
            timesheet.push({
              shopPayrollTime: {
                shop: shop?.shop,
                payrollTime: [{ ...values }],
                total: ts.payroll?.shops?.find(
                  (x) => x?.shop?._id === shop?.shop?._id
                )?.minutes,
              },
            });
          } else {
            timesheet[index]?.shopPayrollTime?.payrollTime?.push({ ...values });
          }
        });
      }
    }

    // Append the total payroll time
    payrollTime.push({ total: ts?.payroll?.minutes });

    timesheet.push({ payrollTime: payrollTime });

    return timesheet;
  }

  static statesList() {
    return [
      { name: "Alabama", abbr: "AL" },
      { name: "Alaska", abbr: "AK" },
      { name: "Arizona", abbr: "AZ" },
      { name: "Arkansas", abbr: "AR" },
      { name: "California", abbr: "CA" },
      { name: "Colorado", abbr: "CO" },
      { name: "Connecticut", abbr: "CT" },
      { name: "Delaware", abbr: "DE" },
      { name: "District of Columbia", abbr: "DC" },
      { name: "Florida", abbr: "FL" },
      { name: "Georgia", abbr: "GA" },
      { name: "Hawaii", abbr: "HI" },
      { name: "Idaho", abbr: "ID" },
      { name: "Illinois", abbr: "IL" },
      { name: "Indiana", abbr: "IN" },
      { name: "Iowa", abbr: "IA" },
      { name: "Kansa", abbr: "KS" },
      { name: "Kentucky", abbr: "KY" },
      { name: "Lousiana", abbr: "LA" },
      { name: "Maine", abbr: "ME" },
      { name: "Maryland", abbr: "MD" },
      { name: "Massachusetts", abbr: "MA" },
      { name: "Michigan", abbr: "MI" },
      { name: "Minnesota", abbr: "MN" },
      { name: "Mississippi", abbr: "MS" },
      { name: "Missouri", abbr: "MO" },
      { name: "Montana", abbr: "MT" },
      { name: "Nebraska", abbr: "NE" },
      { name: "Nevada", abbr: "NV" },
      { name: "New Hampshire", abbr: "NH" },
      { name: "New Jersey", abbr: "NJ" },
      { name: "New Mexico", abbr: "NM" },
      { name: "New York", abbr: "NY" },
      { name: "North Carolina", abbr: "NC" },
      { name: "North Dakota", abbr: "ND" },
      { name: "Ohio", abbr: "OH" },
      { name: "Oklahoma", abbr: "OK" },
      { name: "Oregon", abbr: "OR" },
      { name: "Pennsylvania", abbr: "PA" },
      { name: "Rhode Island", abbr: "RI" },
      { name: "South Carolina", abbr: "SC" },
      { name: "South Dakota", abbr: "SD" },
      { name: "Tennessee", abbr: "TN" },
      { name: "Texas", abbr: "TX" },
      { name: "Utah", abbr: "UT" },
      { name: "Vermont", abbr: "VT" },
      { name: "Virginia", abbr: "VA" },
      { name: "Washington", abbr: "WA" },
      { name: "West Virginia", abbr: "WV" },
      { name: "Wisconsin", abbr: "WI" },
      { name: "Wyoming", abbr: "WY" },
    ];
  }

  static weekDays() {
    return [
      { name: "Monday", abbr: "Mon", value: 1 },
      { name: "Tuesday", abbr: "Tue", value: 2 },
      { name: "Wednesday", abbr: "Wed", value: 3 },
      { name: "Thursday", abbr: "Thu", value: 4 },
      { name: "Friday", abbr: "Fri", value: 5 },
      { name: "Saturday", abbr: "Sat", value: 6 },
      { name: "Sunday", abbr: "Sun", value: 7 },
    ];
  }

  static jobStatuses() {
    return [
      { label: "Received", index: 1 },
      { label: "Material On Order", index: 3 },
      { label: "Material In Stock", index: 2 },
      { label: "Material Prepped", index: 4 },
      { label: "Job Laid Out", index: 5 },
      { label: "Job Set Up", index: 6 },
      { label: "Job Programmed", index: 7 },
      { label: "Ready For Finishing", index: 8 },
      { label: "Out To Finishing", index: 9 },
      { label: "Ready For Shipping", index: 10 },
      { label: "Partially Shipped", index: 11 },
      { label: "Shipped", index: 12 },
    ];
  }

  static lineItemStatuses(role) {
    return [
      { label: "Received", index: 0, hidden: false },
      { label: "Part Files Ready", index: 1, hidden: false },
      { label: "Material Needed", index: 2, hidden: false },
      { label: "Material Requested", index: 3, hidden: false },
      { label: "Material On Order", index: 4, hidden: false },
      { label: "Material In Stock", index: 5, hidden: false },
      { label: "Material Prepped", index: 6, hidden: false },
      { label: "Part Programmed", index: 7, hidden: false },
      { label: "Part Set Up", index: 8, hidden: false },
      { label: "Part Machining", index: 9, hidden: false },
      { label: "Ready For Finishing", index: 10, hidden: false },
      { label: "Out To Finishing", index: 11, hidden: false },
      { label: "Ready For Shipping", index: 12, hidden: false },
      { label: "Partially Shipped", index: 13, hidden: false },
      {
        label: "Shipped",
        index: 14,
        hidden: role ? !role?.canMarkLineItemsAsShipped : false,
      },
    ].filter((status) => status.hidden === false);
  }

  static jobFileTypes() {
    return [
      "Quote",
      "Purchase Order",
      "Blank Shipping Ticket",
      "Signed Shipping Ticket",
      "Vendor Shipping Ticket",
      "Material Order Confirmation",
      "Shipping Label",
      "Delivery Confirmation",
    ];
  }

  static jobSources() {
    return ["Verbal", "Legacy Quote", "Dashboard Quote"];
  }

  static maintenanceFrequencyUnits() {
    return ["Hours", "Days", "Weeks", "Months"];
  }

  static materialTypes() {
    return [
      "Alloy Steel",
      "Aluminum",
      "Beryllium Copper",
      "Brass",
      "Bronze",
      "Carbon Steel",
      "Cast Iron",
      "Copper",
      "Plastics",
      "Stainless Steel",
      "Titanium",
      "Tool Steel",
    ];
  }

  static materialShapes() {
    return [
      "Round",
      "Square",
      "Rectangle",
      "Plate",
      "Rectangular Tube",
      "Round Tube",
    ];
  }

  static subcontractingProcessList() {
    return [
      "Material Ordering",
      "Material Processing",
      "Programming",
      "Machining",
    ];
  }

  static quoteTerms() {
    return ["NET10", "NET15", "NET30", "NET45", "NET60"];
  }

  static additionalProcesses() {
    return [
      {
        value: "Specialty Machining",
      },
      {
        value: "Grinding",
      },
      {
        value: "Heat Treating",
      },
      {
        value: "Coating",
      },
      {
        value: "Other",
      },
    ];
  }

  // static quotePrepChecklist() {
  //   return [
  //     {
  //       statement: "The product or service falls within our scope of business.",
  //     },
  //     {
  //       statement:
  //         "All necessary information required to complete the quote (i.e; prints, verbal or written communication, etc.) has been provided by the customer.",
  //     },
  //     {
  //       statement:
  //         "A determination was made as to whether the product or service requires a Tier 1 external provider fro pre or post in-house production activities.",
  //     },
  //     {
  //       statement: "Product tolernaces, finishing requirements and lead time were reviewed.",
  //     },
  //     {
  //       statement:
  //         "A determination was made as to whether the product requires extra verification.",
  //     },
  //     {
  //       statement:
  //         "A determination was made as to whether production of the product requires additional tooling or infrastructure.",
  //     },
  //     {
  //       statement:
  //         "A determination was made as to whether production of the product or performace of the service requires additional personell training.",
  //     },
  //   ];
  // }

  static quotePrepChecklist() {
    return {
      checklistItem1: {
        statement: function (shopName) {
          return `The product or service falls within ${shopName}'s scope of business`;
        },
        requiredStatement: function (shopName) {
          return `Product or service must fall within ${shopName}'s scope of business`;
        },
      },
      checklistItem2: {
        statement: function () {
          return `All necessary information required to complete the quote (i.e; prints, verbal or written communication, etc.) has been provided by the customer`;
        },
        requiredStatement: function () {
          return `All necessary information required to complete the quote must be provided by the customer`;
        },
      },
      checklistItem3: {
        statement: function () {
          return `A determination was made as to whether the product or service requires a Tier 1 external provider for pre or post in-house production activities`;
        },
        requiredStatement: function () {
          return `A determination must be made as to whether the product or service requires a Tier 1 external provider for pre or post in-house production activities`;
        },
      },
      checklistItem4: {
        statement: function () {
          return `Product tolernaces, finishing requirements and lead time were reviewed`;
        },
        requiredStatement: function () {
          return `Product tolernaces, finishing requirements and lead time must be reviewed`;
        },
      },
      checklistItem5: {
        statement: function () {
          return `A determination was made as to whether the product requires extra verification`;
        },
        requiredStatement: function () {
          return `A determination must be made as to whether the product requires extra verification`;
        },
      },
      checklistItem5: {
        statement: function () {
          return `A determination was made as to whether production of the product requires additional tooling or infrastructure`;
        },
        requiredStatement: function () {
          return `A determination must be made as to whether production of the product requires additional tooling or infrastructure`;
        },
      },
      checklistItem6: {
        statement: function () {
          return `A determination was made as to whether production of the product or performace of the service requires additional personell training`;
        },
        requiredStatement: function () {
          return `A determination must be made as to whether production of the product or performace of the service requires additional personell training`;
        },
      },
      checklistItem7: {
        statement: function () {
          return `Additional Process Planning`;
        },
        requiredStatement: function () {
          return `Please describe the additional process planning`;
        },
      },
    };
  }

  static verbalJobChecklist() {
    return {
      checklistItem1: {
        statement: function (shopName) {
          return `Job falls within ${shopName}'s scope of business`;
        },
      },
      checklistItem2: {
        statement: function () {
          return `All necessary information required to complete the job (i.e; prints, material) has been provided by the customer`;
        },
      },
      checklistItem3: {
        statement: function (shopName) {
          return `Is this a job that ${shopName} has done before?`;
        },
      },
      checklistItem4: {
        statement: function () {
          return `Will this job require additional infrastructure, tooling, or personel training?`;
        },
      },
      checklistItem5: {
        statement: function () {
          return `Job lead time and change control were discussed with the customer`;
        },
      },
      checklistItem6: {
        statement: function () {
          return `Additional Process Planning`;
        },
        requiredStatement: function () {
          return `Please describe the additional process planning`;
        },
      },
    };
  }

  static gageTypes() {
    return [
      "Caliper",
      "Micrometer",
      "Depth Micrometer",
      "Dial Indicator",
      "Bore Gage",
      "Gage Block",
      "Gage Pin",
      "Thread Pitch Gage",
      "Thread Ring Gage",
      "Thread Plug Gage",
      "Surface Roughness Tester",
      "CMM",
    ];
  }

  static rolesList() {
    return [
      "canCreateRoles",
      "canReadRoles",
      "canUpdateRoles",
      "canDeleteRoles",
      // Users
      "canCreateUsersForAllShops",
      "canReadUsersFromAllShops",
      "canUpdateUsersFromAllShops",
      "canDeleteUsersFromAllShops",
      "canCreateUsersForOwnShop",
      "canReadUsersFromOwnShop",
      "canUpdateUsersFromOwnShop",
      "canDeleteUsersFromOwnShop",
      "canUpdateUsersPasswords",
      // Customers
      "canCreateCustomersForAllShops",
      "canReadCustomersFromAllShops",
      "canUpdateCustomersFromAllShops",
      "canDeleteCustomersFromAllShops",
      "canCreateCustomersForOwnShop",
      "canReadCustomersFromOwnShop",
      "canUpdateCustomersFromOwnShop",
      "canDeleteCustomersFromOwnShop",
      // Annual LLCs
      "canCreateAnnualLLCs",
      "canReadAnnualLLCs",
      "canUpdateAnnualLLCs",
      "canDeleteAnnualLLCs",
      // Shops
      "canCreateShops",
      "canReadShops",
      "canUpdateShops",
      "canDeleteShops",
      // Jobs
      "canCreateJobsForOwnShop",
      "canReadJobsFromOwnShop",
      "canUpdateJobsFromOwnShop",
      "canDeleteJobsFromOwnShop",
      "canArchiveJobsFromOwnShop",
      "canCreateJobsForAllShops",
      "canReadJobsFromAllShops",
      "canUpdateJobsFromAllShops",
      "canDeleteJobsFromAllShops",
      "canArchiveJobsFromAllShops",
      "canReadArchivedJobs",
      "canAddLineItemsToExistingJobs",
      // Quotes
      "canCreateQuotesForOwnShop",
      "canReadQuotesFromOwnShop",
      "canUpdateQuotesFromOwnShop",
      "canDeleteQuotesFromOwnShop",
      "canArchiveQuotesFromOwnShop",
      "canCreateQuotesForAllShops",
      "canReadQuotesFromAllShops",
      "canUpdateQuotesFromAllShops",
      "canDeleteQuotesFromAllShops",
      "canArchiveQuotesFromAllShops",
      "canReadArchivedQuotes",
      // Tasks
      "canCreateTasks",
      "canReadTasks",
      "canUpdateTasks",
      "canDeleteTasks",
      "canCreateItemsForOwnShop",
      "canReadItemsFromOwnShop",
      "canUpdateItemsFromOwnShop",
      "canDeleteItemsFromOwnShop",
      "canCreateItemsForAllShops",
      "canReadItemsFromAllShops",
      "canUpdateItemsFromAllShops",
      "canDeleteItemsFromAllShops",
      "canAttachPurchaseOrdersToJobs",
      "canReadPurchaseOrdersFromJobs",
      "canRemovePurchaseOrdersFromJobs",
      "canAttachMaterialOrderConfirmationsToJobs",
      "canReadMaterialOrderConfirmationsFromJobs",
      "canRemoveMaterialOrderConfirmationsFromJobs",
      "canDeleteLineItemFiles",
      // Machines
      "canCreateMachinesForOwnShop",
      "canReadMachinesFromOwnShop",
      "canUpdateMachinesFromOwnShop",
      "canDeleteMachinesFromOwnShop",
      "canCreateMachinesForAllShops",
      "canReadMachinesFromAllShops",
      "canUpdateMachinesFromAllShops",
      "canDeleteMachinesFromAllShops",
      // Machine Maintenance
      "canCreateMachineMaintenanceTypes",
      "canReadMachineMaintenanceTypes",
      "canUpdateMachineMaintenanceTypes",
      "canDeleteMachineMaintenanceTypes",
      // Material Requests
      "canCreateMaterialRequestsForOwnShop",
      "canReadMaterialRequestsFromOwnShop",
      "canUpdateMaterialRequestsFromOwnShop",
      "canDeleteMaterialRequestsFromOwnShop",
      "canCreateMaterialRequestsForAllShops",
      "canReadMaterialRequestsFromAllShops",
      "canUpdateMaterialRequestsFromAllShops",
      "canDeleteMaterialRequestsFromAllShops",
      // Projects
      "canCreateProjectsForOwnShop",
      "canReadProjectsFromOwnShop",
      "canUpdateProjectsFromOwnShop",
      "canDeleteProjectsFromOwnShop",
      "canCreateProjectsForAllShops",
      "canReadProjectsFromAllShops",
      "canUpdateProjectsFromAllShops",
      "canDeleteProjectsFromAllShops",
      // Products
      "canCreateProductsForOwnShop",
      "canReadProductsFromOwnShop",
      "canUpdateProductsFromOwnShop",
      "canDeleteProductsFromOwnShop",
      "canCreateProductsForAllShops",
      "canReadProductsFromAllShops",
      "canUpdateProductsFromAllShops",
      "canDeleteProductsFromAllShops",
      "canAttachFilesToProducts",
      "canRemoveFilesFromProducts",
      //
      "canMarkLineItemsAsShipped",
      "canSubcontractLineItemsToOtherShops",
      "canClockOutUsers",
      "canReadOwnTimesheet",
      "canUpdateOwnTimesheet",
      "canSubmitOwnTimesheet",
      "canReadTimesheetsForUsersFromOwnShop",
      "canReadTimesheetsForUsersFromAllShops",
      "canApproveTimesheetsForAllUsers",
      "canApproveTimesheetsForSubordinates",
      "canAddBlankShippingTicketsToJobs",
      "canRemoveBlankShippingTicketsFromJobs",
      "canAttachQuoteFilesToJobs",
      "canReadQuoteFilesFromJobs",
      "canRemoveQuoteFilesFromJobs",
      "canUseQuickbooksIntegration",
      "canViewDatabaseIds",
      "canCreateSchedules",
      "canReadSchedules",
      "canUpdateSchedules",
      "canDeleteSchedules",
      "canExportTimesheetsToQuickbooks",
      "canReadFilesSentToOffice",
      "canReadAuctionItems",
    ];
  }
}

export default Utils;
