import { apiSlice } from "../slices/apiSlice";

// Define a service using a base URL and expected endpoints
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllNotifications: builder.query({
      query: () => "v1/notifications/",
    }),
    markNotificationAsRead: builder.mutation({
      query: ({ id }) => ({
        url: `v1/notifications/${id}/markRead`,
        method: "PATCH",
      }),
    }),
    clearNotifications: builder.mutation({
      query: () => ({
        url: `v1/notifications/`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllNotificationsQuery,
  useMarkNotificationAsReadMutation,
  useClearNotificationsMutation,
} = extendedApiSlice;
