import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addProductDrawerVisible: false,
  updateProductDrawerVisible: false,
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    showAddProductDrawer: (state) => {
      state.addProductDrawerVisible = true;
    },
    closeAddProductDrawer: (state) => {
      state.addProductDrawerVisible = false;
    },
    showUpdateProductDrawer: (state) => {
      state.updateProductDrawerVisible = true;
    },
    closeUpdateProductDrawer: (state) => {
      state.updateProductDrawerVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showAddProductDrawer,
  closeAddProductDrawer,
  showUpdateProductDrawer,
  closeUpdateProductDrawer,
} = productsSlice.actions;

export default productsSlice.reducer;
