import { Drawer, Col, Row, Empty, Divider, Typography, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { closeCustomerRejectedPartDrawer } from "redux/slices/jobs";
import { useGetCustomerRejectedPartQuery } from "redux/api/customerRejectedPartsApi";
import moment from "moment";
import { Link } from "react-router-dom";

const CustomerRejectedPartDrawer = ({ id }) => {
  const visible = useSelector(
    (state) => state.app.jobs.customerRejectedPartDrawerVisible
  );
  const user = useSelector((state) => state.auth.user.info);
  let dispatch = useDispatch();

  const customerRejectedPartsQuery = useGetCustomerRejectedPartQuery(
    {
      id: id,
    },
    { skip: !id, refetchOnMountOrArgChange: true }
  );

  return (
    <>
      <Drawer
        title={`${customerRejectedPartsQuery?.data?.lineItem?.lineItemDescription}`}
        width={"auto"}
        drawerStyle={{
          width: `100vw`,
          maxWidth: `${560}px`,
        }}
        onClose={() => dispatch(closeCustomerRejectedPartDrawer())}
        visible={visible}
        bodyStyle={{ padding: 0, paddingBottom: 80 }}
        footer={null}
        maskClosable={true}
      >
        <Spin
          spinning={
            customerRejectedPartsQuery.isLoading ||
            customerRejectedPartsQuery.isFetching
          }
        >
          <div className="p-4">
            <div className="mb-3">
              <span className="font-weight-semibold">Job</span>
              <br />
              {customerRejectedPartsQuery?.data?.lineItem?.job?.jobNumber}
            </div>
            <div className="mb-3">
              <span className="font-weight-semibold">Line Item</span>
              <br />
              {customerRejectedPartsQuery?.data?.lineItem?.lineItemDescription}
            </div>
            <div className="mb-3">
              <span className="font-weight-semibold">Date Rejected</span>
              <br />
              {moment(customerRejectedPartsQuery?.data?.rejectDate).format(
                "MM/DD/YYYY"
              )}
            </div>
            <div className="mb-3">
              <span className="font-weight-semibold">Quantity Rejected</span>
              <br />
              {customerRejectedPartsQuery?.data?.quantity}
            </div>
            <div className="mb-3">
              <span className="font-weight-semibold">Reason for Rejection</span>
              <Typography.Paragraph>
                {customerRejectedPartsQuery?.data?.reason}
              </Typography.Paragraph>
            </div>
            <div className="mb-3">
              <span className="font-weight-semibold">Disposition</span>
              <Typography.Paragraph>
                {customerRejectedPartsQuery?.data?.disposition}
              </Typography.Paragraph>
            </div>

            <div className="mb-3">
              <span className="font-weight-semibold">
                Supporting Documentation
              </span>
            </div>

            <Row gutter={[16, 0]}>
              {customerRejectedPartsQuery?.data?.files &&
              customerRejectedPartsQuery?.data?.files?.length > 0 ? (
                customerRejectedPartsQuery?.data?.files?.map((file) => {
                  return (
                    <Col span={24}>
                      <a href={file?.url} target="_blank" rel="noreferrer">
                        {file?.originalname}
                      </a>
                    </Col>
                  );
                })
              ) : (
                <Col span={24}>
                  <Empty
                    description="No Supporting Documentation"
                    // image={Empty.PRESENTED_IMAGE_SIMPLE}
                    style={{ margin: 0 }}
                  />
                </Col>
              )}
            </Row>
          </div>
        </Spin>
      </Drawer>
    </>
  );
};

export default CustomerRejectedPartDrawer;
