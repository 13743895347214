import { Progress, Tag } from "antd";
import Flex from "../Flex";

const JobStatus = ({ job, type }) => (
  <Flex alignItems="center">
    <>
      <Progress
        type={type}
        percent={job.progress}
        width={50}
        strokeWidth={8}
        style={{ fontWeight: "600" }}
        // format={(percent) => (percent === "100" ? "Done" : `${percent}%`)}
      />
    </>
  </Flex>
);

export default JobStatus;
