import React, { useState } from "react";
import { Card, Table, Input, Button, Tag } from "antd";
import dayjs from "dayjs";

// Components
import { EyeOutlined, SearchOutlined, PlusOutlined } from "@ant-design/icons";
import Flex from "components/shared-components/Flex";

// Redux
import PageTitle from "components/shared-components/PageTitle";
import { useGetOrdersQuery } from "redux/api/linkageKitsApi";

const LinkageKitsOrders = () => {
  const { data, error, isLoading, refetch } = useGetOrdersQuery();

  const tableColumns = [
    {
      title: "Order Number",
      render: (order) => <Flex alignItems="center">{`${order.orderNumber}`}</Flex>,
    },
    {
      title: "Order Date",
      render: (order) => (
        <Flex alignItems="center">{`${dayjs(order.submittedAt).format("MMM-DD-YYYY")}`}</Flex>
      ),
    },
    {
      title: "Status",
      render: (order) => (
        <Flex alignItems="center">
          <Tag>{order.status}</Tag>
        </Flex>
      ),
    },
    {
      title: "Customer",
      render: (order) => (
        <Flex alignItems="center">{`${order.user?.firstName} ${order.user?.lastName}`}</Flex>
      ),
    },
    {
      title: "Line Items",
      render: (order) => <Flex alignItems="center">{`${order.items.length}`}</Flex>,
    },
    {
      title: "Total",
      render: (order) => <Flex alignItems="center">{`$${order.total}`}</Flex>,
    },
    {
      title: "",
      render: (_id, record) => (
        <div className="text-right">
          <Button type="ghost">
            <EyeOutlined />
            View
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <PageTitle title="Orders" />
      <Card>
        <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
          <Flex className="mb-1" mobileFlex={false}>
            <div className="mr-md-3 mb-3">
              <Input placeholder="Search" prefix={<SearchOutlined />} />
            </div>
          </Flex>
          {/* <div>
            <Button type="primary" icon={<PlusOutlined />} block>
              Add Machine
            </Button>
          </div> */}
        </Flex>
        <div className="table-responsive">
          <Table columns={tableColumns} dataSource={data} rowKey="id" loading={isLoading} />
        </div>
      </Card>
    </>
  );
};

export default LinkageKitsOrders;
