import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  Table,
  Input,
  Button,
  Tag,
  Progress,
  Typography,
  Badge,
  Dropdown,
  Menu,
  Tooltip,
  Grid,
  List,
  Avatar,
  Row,
  Col,
  Radio,
  Switch,
  Checkbox,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import FeatherIcon from "feather-icons-react";
import moment from "moment";

// Components
import {
  EyeOutlined,
  SearchOutlined,
  PlusOutlined,
  EyeFilled,
  LineHeightOutlined,
  MoreOutlined,
  ArrowDownOutlined,
  AlertFilled,
  WarningFilled,
  EditOutlined,
  DeleteOutlined,
  PaperClipOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  AppstoreOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import utils from "utils";

// Redux
import EllipsisDropdown from "components/shared-components/EllipsisDropdown";
import DueDate from "components/shared-components/DueDate";
import JobStatus from "components/shared-components/JobStatus";
import { useNavigate, useParams } from "react-router";

const ItemAction = ({ id, removeId }) => (
  <EllipsisDropdown
    menu={
      <Menu>
        <Menu.Item key="0">
          <EyeOutlined />
          <span className="ml-2">View</span>
        </Menu.Item>
        <Menu.Item key="1">
          <EditOutlined />
          <span className="ml-2">Edit</span>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="2" onClick={() => removeId(id)}>
          <DeleteOutlined />
          <span className="ml-2">Delete Project</span>
        </Menu.Item>
      </Menu>
    }
  />
);

const ItemHeader = ({ user, job, getSoonestDueDate }) => (
  <div className="d-flex flex-row">
    <div>
      <div className="d-flex align-items-center">
        <h4 className="mb-0">
          {user?.role.canReadJobsFromAllShops
            ? job.jobNumber
            : job.jobNumber.slice(-3)}
        </h4>
        <span
          className="ml-2 pl-2"
          style={{
            fontSize: "0.9rem",
            borderLeft: "1px solid #ccc",
          }}
        >
          {job.customer.customerCode}
        </span>
      </div>
      <span className="text-muted">{job.description}</span>
    </div>
    <div className="ml-auto">
      <Tag
        className="d-flex mr-0"
        style={{
          ...utils.getDueDateTagColor(getSoonestDueDate(job), job?.progress),
          border: "none",
        }}
      >
        <span className="mr-1">Due</span>
        <DueDate getSoonestDueDate={getSoonestDueDate} job={job} />
      </Tag>
    </div>
  </div>
);

const ItemInfo = ({
  attachmentCount,
  completedTask,
  totalTask,
  statusColor,
  dayleft,
}) => (
  <Flex alignItems="center">
    <div className="mr-3">
      <Tooltip title="Attachment">
        <PaperClipOutlined className="text-muted font-size-md" />
        <span className="ml-1 text-muted">{attachmentCount}</span>
      </Tooltip>
    </div>
    <div className="mr-3">
      <Tooltip title="Task Completed">
        <CheckCircleOutlined className="text-muted font-size-md" />
        <span className="ml-1 text-muted">
          {completedTask}/{totalTask}
        </span>
      </Tooltip>
    </div>
    <div>
      <Tag
        className={statusColor === "none" ? "bg-gray-lightest" : ""}
        color={statusColor !== "none" ? statusColor : ""}
      >
        <ClockCircleOutlined />
        <span className="ml-2 font-weight-semibold">{dayleft} days left</span>
      </Tag>
    </div>
  </Flex>
);

const ItemMember = ({ member }) => (
  <>
    {member.map((elm, i) =>
      i <= 2 ? (
        <Tooltip title={elm.name} key={`avatar-${i}`}>
          <Avatar
            size="small"
            className={`ml-1 cursor-pointer ant-avatar-${elm.avatarColor}`}
            src={elm.img}
          >
            {elm.img ? (
              ""
            ) : (
              <span className="font-weight-semibold font-size-sm">
                {utils.getNameInitial(elm.name)}
              </span>
            )}
          </Avatar>
        </Tooltip>
      ) : null
    )}
    {member.length > 3 ? (
      <Tooltip title={`${member.length - 3} More`}>
        <Avatar
          size={25}
          className="ml-1 cursor-pointer bg-white border font-size-sm"
        >
          <span className="text-gray-light font-weight-semibold">
            +{member.length - 3}
          </span>
        </Avatar>
      </Tooltip>
    ) : null}
  </>
);

const ListItem = ({
  user,
  data,
  dispatch,
  setSelectedJob,
  showUpdateJobDrawer,
  getSoonestDueDate,
}) => {
  let navigate = useNavigate();

  return (
    <div
      className="bg-white rounded p-3 mb-3 border"
      style={{ cursor: "pointer" }}
      onClick={() => {
        navigate(`/dashboard/jobs/${data._id}`);
      }}
    >
      <Row align="middle">
        <Col xs={24} sm={24} md={8}>
          <ItemHeader
            user={user}
            job={data}
            getSoonestDueDate={getSoonestDueDate}
          />
        </Col>

        {/* <Col xs={24} sm={24} md={6}>
          <ItemInfo />
        </Col> */}
        <Col xs={24} sm={24} md={5}>
          <JobStatus job={data} type="line" />
        </Col>
        {/* <Col xs={24} sm={24} md={3}>
          <div className="ml-0 ml-md-3">
            <ItemMember member={data.member} />
          </div>
        </Col>
        <Col xs={24} sm={24} md={2}>
          <div className="text-right">
            <ItemAction id={data.id} removeId={removeId} />
          </div>
        </Col> */}
      </Row>
    </div>
  );
};

// const GridItem = ({ data, removeId }) => (
//   <Card>
//     <Flex alignItems="center" justifyContent="between">
//       <ItemHeader name={data.name} category={data.category} />
//       <ItemAction id={data.id} removeId={removeId} />
//     </Flex>
//     <div className="mt-2">
//       <ItemInfo
//         attachmentCount={data.attachmentCount}
//         completedTask={data.completedTask}
//         totalTask={data.totalTask}
//         statusColor={data.statusColor}
//         dayleft={data.dayleft}
//       />
//     </div>
//     <div className="mt-3">
//       <ItemProgress progression={data.progression} />
//     </div>
//     <div className="mt-2">
//       <ItemMember member={data.member} />
//     </div>
//   </Card>
// );

export const MobileJobsList = ({
  user,
  jobs,
  dispatch,
  setSearchValue,
  setSelectedJob,
  showUpdateJobDrawer,
  getSoonestDueDate,
  showArchived,
  setShowArchived,
}) => {
  const VIEW_LIST = "LIST";
  const VIEW_GRID = "GRID";

  const [view, setView] = useState(VIEW_LIST);

  let urlParams = useParams();
  let navigate = useNavigate();

  const onChangeProjectView = (e) => {
    setView(e.target.value);
  };

  useEffect(() => {
    if (urlParams.id) {
      dispatch(showUpdateJobDrawer());
    }
  }, []);

  return (
    <>
      <div className="container-fluid">
        <Flex justifyContent="between" alignItems="center" className="pb-3">
          <div className="w-100">
            <Input
              placeholder="Search"
              prefix={<SearchOutlined />}
              onChange={(e) => setSearchValue(e.currentTarget.value)}
            />
          </div>
        </Flex>
        <div>
          <Button
            onClick={() => navigate("/dashboard/jobs/add")}
            type="primary"
            icon={<PlusOutlined />}
            block
            className="mb-2"
          >
            Add Job
          </Button>
        </div>
        <div>
          <Checkbox
            checked={JSON.parse(showArchived)}
            onChange={(e) => setShowArchived(e.target.checked)}
            style={{ fontWeight: 500 }}
          >
            Show Archived Jobs
          </Checkbox>
        </div>
      </div>

      <div
        className={`my-4 ${
          view === VIEW_LIST ? "container" : "container-fluid"
        }`}
      >
        {
          view === VIEW_LIST
            ? jobs?.map((elm) => (
                <ListItem
                  user={user}
                  data={elm}
                  key={elm.id}
                  dispatch={dispatch}
                  setSelectedJob={setSelectedJob}
                  showUpdateJobDrawer={showUpdateJobDrawer}
                  getSoonestDueDate={getSoonestDueDate}
                />
              ))
            : null
          // <Row gutter={16}>
          //   {jobs?.map((elm) => (
          //     <Col xs={24} sm={24} lg={8} xl={8} xxl={6} key={elm.id}>
          //       <GridItem data={elm} />
          //     </Col>
          //   ))}
          // </Row>
        }
      </div>
    </>
  );
};
