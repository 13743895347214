import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addAnnualLLCDrawerVisible: false,
  updateAnnualLLCDrawerVisible: false,
};

export const annualLLCsSlice = createSlice({
  name: "annualLLCs",
  initialState,
  reducers: {
    showAddAnnualLLCDrawer: (state) => {
      state.addAnnualLLCDrawerVisible = true;
    },
    closeAddAnnualLLCDrawer: (state) => {
      state.addAnnualLLCDrawerVisible = false;
    },
    showUpdateAnnualLLCDrawer: (state) => {
      state.updateAnnualLLCDrawerVisible = true;
    },
    closeUpdateAnnualLLCDrawer: (state) => {
      state.updateAnnualLLCDrawerVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showAddAnnualLLCDrawer,
  closeAddAnnualLLCDrawer,
  showUpdateAnnualLLCDrawer,
  closeUpdateAnnualLLCDrawer,
} = annualLLCsSlice.actions;

export default annualLLCsSlice.reducer;
