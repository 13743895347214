import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addShopDrawerVisible: false,
  updateShopDrawerVisible: false,
};

export const shopsSlice = createSlice({
  name: "shops",
  initialState,
  reducers: {
    showAddShopDrawer: (state) => {
      state.addShopDrawerVisible = true;
    },
    closeAddShopDrawer: (state) => {
      state.addShopDrawerVisible = false;
    },
    showUpdateShopDrawer: (state) => {
      state.updateShopDrawerVisible = true;
    },
    closeUpdateShopDrawer: (state) => {
      state.updateShopDrawerVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showAddShopDrawer,
  closeAddShopDrawer,
  showUpdateShopDrawer,
  closeUpdateShopDrawer,
} = shopsSlice.actions;

export default shopsSlice.reducer;
