import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addMachineDrawerVisible: false,
  updateMachineDrawerVisible: false,
  maintenanceTypeModalVisible: false,
};

export const machinesSlice = createSlice({
  name: "machines",
  initialState,
  reducers: {
    showAddMachineDrawer: (state) => {
      state.addMachineDrawerVisible = true;
    },
    closeAddMachineDrawer: (state) => {
      state.addMachineDrawerVisible = false;
    },
    showUpdateMachineDrawer: (state) => {
      state.updateMachineDrawerVisible = true;
    },
    closeUpdateMachineDrawer: (state) => {
      state.updateMachineDrawerVisible = false;
    },
    showMaintenanceTypeModal: (state) => {
      state.maintenanceTypeModalVisible = true;
    },
    closeMaintenanceTypeModal: (state) => {
      state.maintenanceTypeModalVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showAddMachineDrawer,
  closeAddMachineDrawer,
  showUpdateMachineDrawer,
  closeUpdateMachineDrawer,
  showMaintenanceTypeModal,
  closeMaintenanceTypeModal,
} = machinesSlice.actions;

export default machinesSlice.reducer;
