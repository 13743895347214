import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Table,
  Alert,
  InputNumber,
} from "antd";
import {
  useGetManufactureRatesQuery,
  useUpdateManufactureRateMutation,
} from "redux/api/linkageKitsApi";
import PageTitle from "components/shared-components/PageTitle";
import { EditOutlined } from "@ant-design/icons";

const ManufactureRates = () => {
  const dispatch = useDispatch();

  const getManufactureRates = useGetManufactureRatesQuery();
  const [updateManufactureRate, result] = useUpdateManufactureRateMutation();

  const [selectedManufactureRate, setSelectedManufactureRate] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const [form] = Form.useForm();

  const openNotification = (type, message, description) => {
    notification[type]({
      message,
      description,
    });
  };

  const tableColumns = [
    {
      title: "Kit Size",
      render: (record) => record.name,
    },
    {
      title: "Components",
      render: (record) => record.components,
    },
    {
      title: "Additional Options",
      render: (record) => {
        return record.valveKeyway && record.actuatorKeyway
          ? "Keyway on Valve and Actuator"
          : record.valveKeyway && !record.actuatorKeyway
          ? "Keyway on Valve"
          : record.valveKeyway && !record.actuatorKeyway
          ? "Keyway on Actuator"
          : null;
      },
    },
    {
      title: "Multiplier",
      render: (record) => record.multiplier,
    },
    {
      title: "Actions",
      align: "right",
      render: (record) => (
        <Button
          icon={<EditOutlined />}
          onClick={() => {
            console.log(record.multiplier);
            setSelectedManufactureRate(record);
            setModalVisible(true);
          }}
        >
          Edit
        </Button>
      ),
    },
  ];

  return (
    <>
      <PageTitle title="Manufacture Rates" />

      <Row>
        <Col xs={24}>
          <Card title="Bracket and Coupler">
            <Table
              columns={tableColumns}
              dataSource={getManufactureRates?.data?.filter(
                (line) => line.components === "Bracket and Coupler"
              )}
              loading={getManufactureRates.isLoading}
              pagination={false}
            />
          </Card>
          <Card title="Coupler Only">
            <Table
              columns={tableColumns}
              dataSource={getManufactureRates?.data?.filter(
                (line) => line.components === "Coupler Only"
              )}
              loading={getManufactureRates.isLoading}
              pagination={false}
            />
          </Card>
          <Card title="Bracket Only">
            <Table
              columns={tableColumns}
              dataSource={getManufactureRates?.data?.filter(
                (line) => line.components === "Bracket Only"
              )}
              loading={getManufactureRates.isLoading}
              pagination={false}
            />
          </Card>
        </Col>
      </Row>

      <Modal
        visible={modalVisible}
        centered
        title="Update Multiplier"
        onCancel={() => {
          setSelectedManufactureRate(null);
          setModalVisible(false);
        }}
        destroyOnClose={true}
        onOk={form.submit}
      >
        <Form
          form={form}
          layout="vertical"
          preserve={false}
          onFinish={(values) => {
            updateManufactureRate({
              id: selectedManufactureRate._id,
              values: {
                multiplier: values.multiplier,
              },
            })
              .unwrap()
              .then(() => {
                setSelectedManufactureRate(null);
                setModalVisible(false);
                openNotification("success", "Multiplier updated successfully");
                getManufactureRates.refetch();
              })
              .catch((err) => {
                setModalVisible(false);
                openNotification("error", "Error updating multiplier", err.data.message);
              });
          }}
        >
          <Form.Item name="multiplier" label="Multiplier">
            <InputNumber
              defaultValue={Number(selectedManufactureRate?.multiplier)}
              precision={2}
              step={0.5}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ManufactureRates;
