import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addRoleDrawerVisible: false,
  updateRoleDrawerVisible: false,
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    showAddRoleDrawer: (state) => {
      state.addRoleDrawerVisible = true;
    },
    closeAddRoleDrawer: (state) => {
      state.addRoleDrawerVisible = false;
    },
    showUpdateRoleDrawer: (state) => {
      state.updateRoleDrawerVisible = true;
    },
    closeUpdateRoleDrawer: (state) => {
      state.updateRoleDrawerVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showAddRoleDrawer,
  closeAddRoleDrawer,
  showUpdateRoleDrawer,
  closeUpdateRoleDrawer,
} = rolesSlice.actions;

export default rolesSlice.reducer;
