import "./styles.css";
import { ArrowLeftOutlined, CloseOutlined, PrinterFilled } from "@ant-design/icons";
import { Alert, Button, Col, DatePicker, Form, Row, Table } from "antd";
import Loading from "components/shared-components/Loading";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ReactSignatureCanvas from "react-signature-canvas";
import { useGetJobByIdQuery } from "redux/api/jobsApi";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

const PackingSlip = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const job = useGetJobByIdQuery({ id: id });

  const [form] = Form.useForm();
  const sigRef = useRef(null);

  const [lineItems, setLineItems] = useState([]);
  const [shipDate, setShipDate] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [billTo, setBillTo] = useState(null);
  const [shipTo, setShipTo] = useState(null);

  useEffect(() => {
    if (job.isSuccess) {
      setLineItems(
        searchParams.get("lineItems")
          ? job?.currentData?.lineItems?.filter((lineItem) =>
              searchParams.get("lineItems").split(",").includes(lineItem._id.toString())
            )
          : job?.currentData?.lineItems
      );

      setShipDate(moment());

      setBillTo(
        job?.currentData?.customer?.addresses?.billing.find(
          (address) => address.default === true
        ) || null
      );
      setShipTo(
        job?.currentData?.customer?.addresses?.shipping.find(
          (address) => address.default === true
        ) || null
      );
      setCustomerName(
        job?.currentData?.customer?.name
          ? job?.currentData?.customer?.name
          : `${job?.currentData?.customer?.firstName} ${job?.currentData?.customer?.lastName}`
      );
    }
  }, [job]);

  return (
    <>
      {job?.isLoading ? (
        <Loading cover="content" />
      ) : job?.isError ? (
        <div
          style={{
            display: "flex",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Alert
            type="error"
            message="Something went wrong :("
            description="Please try again later"
            showIcon={true}
            style={{ width: "300px" }}
          />
        </div>
      ) : (
        <>
          <div className="print-options">
            <Button onClick={() => navigate(`/dashboard/jobs/${id}`)} type="ghost">
              <ArrowLeftOutlined /> BACK
            </Button>
            <Button onClick={() => window.print()} type="primary">
              <PrinterFilled /> PRINT
            </Button>
          </div>
          <div className="packing-slip">
            <Row style={{ marginBottom: "0.25in" }}>
              <Col span={14}>
                <h2
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "900",
                    fontSize: "1.1rem",
                    marginTop: ".5rem",
                    color: "#333",
                  }}
                >
                  {job?.currentData?.shop?.name}
                </h2>
                <span>
                  {!job?.currentData?.shop?.address?.street2
                    ? `${job?.currentData?.shop?.address?.street}`
                    : `${job?.currentData?.shop?.address?.street}, ${job?.currentData?.shop?.address?.street2}`}
                </span>
                <br />
                <span>{`${job?.currentData?.shop?.address?.city}, ${job?.currentData?.shop?.address?.state}, ${job?.currentData?.shop?.address?.zip}`}</span>
                <br />
                <span>{`${job?.currentData?.shop?.email}`}</span>
                <br />
                <span>{`${job?.currentData?.shop?.phone}`}</span>
              </Col>
              <Col span={10}>
                <h2
                  className="d-block text-right mb-4 font-weight-medium"
                  style={{ color: "#333", lineHeight: "1", fontSize: "1.75rem" }}
                >
                  Packing Slip
                </h2>

                <Row gutter={12}>
                  <Col span={12}>
                    <span className="d-block  field-label">Date</span>
                    <div className="ship-date">
                      <span
                        className="d-block"
                        style={{
                          display: "block",
                          border: "1px solid #b5c4d6",
                          padding: "0 0.5rem",
                          height: "0.375in",
                          lineHeight: "0.375in",
                        }}
                      >
                        {shipDate?.format("MM/DD/YYYY")}
                      </span>
                    </div>

                    <div
                      className="ship-date-picker"
                      style={{
                        border: "1px solid #b5c4d6",
                        padding: "0 0.5rem",
                        height: "0.35in",
                        lineHeight: "0.35in",
                      }}
                    >
                      <DatePicker
                        style={{ padding: 0 }}
                        bordered={false}
                        format={"MM/DD/YYYY"}
                        value={shipDate}
                        onChange={(value) => setShipDate(value)}
                      />
                    </div>
                  </Col>
                  <Col span={12}>
                    <span className="d-block  field-label">Purchase Order</span>
                    <span
                      style={{
                        display: "block",
                        border: "1px solid #b5c4d6",
                        padding: "0 0.5rem",
                        height: "0.35in",
                        lineHeight: "0.35in",
                      }}
                    >
                      {job?.currentData?.customerPONumber}
                    </span>
                  </Col>
                </Row>
                <Row gutter={12} className="flex-row-reverse mt-2">
                  <Col span={12}>
                    <span className="d-block  field-label">Job Number</span>
                    <span
                      style={{
                        display: "block",
                        border: "1px solid #b5c4d6",
                        padding: "0 0.5rem",
                        height: "0.35in",
                        lineHeight: "0.35in",
                      }}
                    >
                      {job?.currentData?.jobNumber}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/* <pre>{JSON.stringify(job?.data, null, 3)}</pre> */}

            <Row gutter={16} style={{ marginBottom: "0.25in" }}>
              <Col span={12}>
                <div className="field-label">Bill To</div>
                <div
                  style={{
                    display: "block",
                    border: "1px solid #b5c4d6",
                    padding: "1rem",
                  }}
                >
                  {customerName}
                  <br />
                  {billTo ? (
                    <>
                      {billTo?.street2 !== undefined
                        ? `${billTo?.street}, ${billTo?.street2}`
                        : `${billTo?.street}`}
                      <br />
                      <div>{`${billTo?.city}, ${billTo?.state}, ${billTo?.zip}`}</div>
                    </>
                  ) : null}
                  {job?.currentData?.customer?.phone ? (
                    <>
                      <br />
                      {job?.currentData?.customer?.phone}
                    </>
                  ) : null}
                </div>
              </Col>
              <Col span={12}>
                <div className="field-label">Ship To</div>
                <div
                  style={{
                    display: "block",
                    border: "1px solid #b5c4d6",
                    padding: "1rem",
                  }}
                >
                  {customerName}

                  <br />
                  {shipTo ? (
                    <>
                      {shipTo?.street2 !== undefined
                        ? `${shipTo?.street}, ${shipTo?.street2}`
                        : `${shipTo?.street}`}
                      <br />
                      <div>{`${shipTo?.city}, ${shipTo?.state}, ${shipTo?.zip}`}</div>
                    </>
                  ) : null}
                  {job?.currentData?.customer?.phone ? (
                    <>
                      <br />
                      {job?.currentData?.customer?.phone}
                    </>
                  ) : null}
                </div>
              </Col>
            </Row>

            <table width="100%" style={{ marginBottom: "0.25in" }}>
              <th align="left">Description</th>
              <th align="right">Qty</th>
              {lineItems.map((lineItem) => (
                <tr>
                  <td style={{ padding: "0.375rem 0" }}>{lineItem.lineItemDescription}</td>
                  <td align="right" style={{ padding: "0.375rem 0" }}>
                    {lineItem.lineItemStatus[lineItem.lineItemStatus.length - 1].label ===
                    "Partially Shipped"
                      ? `${
                          lineItem.lineItemStatus[lineItem.lineItemStatus.length - 1]
                            ?.quantityShipped
                        } of ${lineItem.lineItemQuantity}`
                      : lineItem.lineItemQuantity}
                  </td>
                </tr>
              ))}
            </table>

            <Row>
              <Col span={24}>
                <div className="mb-2">
                  <div className="field-label">Signature</div>
                  <div
                    style={{
                      display: "block",
                      backgroundColor: "#feffef",
                      border: "1px solid #b5c4d6",
                    }}
                  >
                    <ReactSignatureCanvas
                      ref={sigRef}
                      canvasProps={{
                        style: {
                          display: "block",
                          width: `100%`,
                          height: "125px",
                        },
                        className: "sigCanvas",
                      }}
                    />
                  </div>
                </div>
                <Button
                  type="default"
                  size="small"
                  htmlType="button"
                  className="clear-signature"
                  onClick={() => sigRef.current.clear()}
                >
                  <CloseOutlined />
                  Clear Signature
                </Button>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  );
};

export default PackingSlip;
