import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import AppLayout from "layouts/app-layout";
// import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";

const Views = (props) => {
  const { locale, location, direction, navCollapsed, navType } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <AppLayout
          direction={direction}
          location={location}
          navCollapsed={navCollapsed}
          navType={navType}
        />
      </ConfigProvider>
    </IntlProvider>
  );
};

export default Views;
