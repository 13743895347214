import { Select } from "antd";
import React from "react";
import { useGetAllShopsQuery } from "redux/api/shopsApi";

const Shops = ({ onChange, defaultValue }) => {
  const shops = useGetAllShopsQuery();

  return (
    <Select
      onChange={(value) => onChange(value)}
      defaultValue={defaultValue}
      style={{ width: "200px" }}
      options={shops?.data?.map((material) => {
        return { value: material._id, label: material.name };
      })}
    />
  );
};

export default Shops;
