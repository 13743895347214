import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addMaterialRequestDrawerVisible: false,
  updateMaterialRequestDrawerVisible: false,
};

export const materialRequestsSlice = createSlice({
  name: "materialRequests",
  initialState,
  reducers: {
    showAddMaterialRequestDrawer: (state) => {
      state.addMaterialRequestDrawerVisible = true;
    },
    closeAddMaterialRequestDrawer: (state) => {
      state.addMaterialRequestDrawerVisible = false;
    },
    showUpdateMaterialRequestDrawer: (state) => {
      state.updateMaterialRequestDrawerVisible = true;
    },
    closeUpdateMaterialRequestDrawer: (state) => {
      state.updateMaterialRequestDrawerVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showAddMaterialRequestDrawer,
  closeAddMaterialRequestDrawer,
  showUpdateMaterialRequestDrawer,
  closeUpdateMaterialRequestDrawer,
} = materialRequestsSlice.actions;

export default materialRequestsSlice.reducer;
