import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addJobDrawerVisible: false,
  updateJobDrawerVisible: false,
  uploadFilesToLineItemModalVisible: false,
  addLineItemChecklistDrawerVisible: false,
  updateLineItemChecklistDrawerVisible: false,
  addLineItemToNewJobDrawerVisible: false,
  addLineItemToExistingJobDrawerVisible: false,
  updateLineItemFromExistingJobDrawerVisible: false,
  subcontractModalVisible: false,
  addPartPhotosModalVisible: false,
  partPhotosDrawerVisible: false,
  customerRejectedPartsDrawerVisible: false,
  customerRejectedPartDrawerVisible: false,
  quotesModalVisible: false,
};

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    showAddJobDrawer: (state) => {
      state.addJobDrawerVisible = true;
    },
    closeAddJobDrawer: (state) => {
      state.addJobDrawerVisible = false;
    },
    showUpdateJobDrawer: (state) => {
      state.updateJobDrawerVisible = true;
    },
    closeUpdateJobDrawer: (state) => {
      state.updateJobDrawerVisible = false;
    },
    showAddLineItemToNewJobDrawer: (state) => {
      state.addLineItemToNewJobDrawerVisible = true;
    },
    closeAddLineItemToNewJobDrawer: (state) => {
      state.addLineItemToNewJobDrawerVisible = false;
    },
    showAddLineItemToExistingJobDrawer: (state) => {
      state.addLineItemToExistingJobDrawerVisible = true;
    },
    closeAddLineItemToExistingJobDrawer: (state) => {
      state.addLineItemToExistingJobDrawerVisible = false;
    },
    showUpdateLineItemFromExistingJobDrawer: (state) => {
      state.updateLineItemFromExistingJobDrawerVisible = true;
    },
    closeUpdateLineItemFromExistingJobDrawer: (state) => {
      state.updateLineItemFromExistingJobDrawerVisible = false;
    },
    showUploadFilesToLineItemModal: (state) => {
      state.uploadFilesToLineItemModalVisible = true;
    },
    closeUploadFilesToLineItemModal: (state) => {
      state.uploadFilesToLineItemModalVisible = false;
    },
    showAddLineItemChecklistDrawer: (state) => {
      state.addLineItemChecklistDrawerVisible = true;
    },
    closeAddLineItemChecklistDrawer: (state) => {
      state.addLineItemChecklistDrawerVisible = false;
    },
    showUpdateLineItemChecklistDrawer: (state) => {
      state.updateLineItemChecklistDrawerVisible = true;
    },
    closeUpdateLineItemChecklistDrawer: (state) => {
      state.updateLineItemChecklistDrawerVisible = false;
    },
    showSubcontractModal: (state) => {
      state.subcontractModalVisible = true;
    },
    closeSubcontractModal: (state) => {
      state.subcontractModalVisible = false;
    },
    showAddPartPhotosModal: (state) => {
      state.addPartPhotosModalVisible = true;
    },
    closeAddPartPhotosModal: (state) => {
      state.addPartPhotosModalVisible = false;
    },
    showPartPhotosDrawer: (state) => {
      state.partPhotosDrawerVisible = true;
    },
    closePartPhotosDrawer: (state) => {
      state.partPhotosDrawerVisible = false;
    },
    showCustomerRejectedPartsDrawer: (state) => {
      state.customerRejectedPartsDrawerVisible = true;
    },
    closeCustomerRejectedPartsDrawer: (state) => {
      state.customerRejectedPartsDrawerVisible = false;
    },
    showCustomerRejectedPartDrawer: (state) => {
      state.customerRejectedPartDrawerVisible = true;
    },
    closeCustomerRejectedPartDrawer: (state) => {
      state.customerRejectedPartDrawerVisible = false;
    },
    showQuotesModal: (state) => {
      state.quotesModalVisible = true;
    },
    closeQuotesModal: (state) => {
      state.quotesModalVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showAddJobDrawer,
  closeAddJobDrawer,
  showUpdateJobDrawer,
  closeUpdateJobDrawer,
  showUploadFilesToLineItemModal,
  closeUploadFilesToLineItemModal,
  showAddLineItemChecklistDrawer,
  closeAddLineItemChecklistDrawer,
  showUpdateLineItemChecklistDrawer,
  closeUpdateLineItemChecklistDrawer,
  showAddLineItemToNewJobDrawer,
  closeAddLineItemToNewJobDrawer,
  showAddLineItemToExistingJobDrawer,
  closeAddLineItemToExistingJobDrawer,
  showUpdateLineItemFromExistingJobDrawer,
  closeUpdateLineItemFromExistingJobDrawer,
  showSubcontractModal,
  closeSubcontractModal,
  showAddPartPhotosModal,
  closeAddPartPhotosModal,
  showPartPhotosDrawer,
  closePartPhotosDrawer,
  showCustomerRejectedPartsDrawer,
  closeCustomerRejectedPartsDrawer,
  showCustomerRejectedPartDrawer,
  closeCustomerRejectedPartDrawer,
  showQuotesModal,
  closeQuotesModal,
} = jobsSlice.actions;

export default jobsSlice.reducer;
