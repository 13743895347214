import React, { useEffect, useState } from "react";
import {
  Card,
  Table,
  Input,
  Button,
  Tag,
  Typography,
  Badge,
  Tooltip,
  Grid,
  Select,
  Form,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate, useLocation, Link } from "react-router-dom";

// Components
import { SearchOutlined, PlusOutlined, WarningFilled } from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import utils from "utils";

// Redux
import { useGetAllJobsQuery } from "redux/api/jobsApi";
import { MobileJobsList } from "./mobile";
import JobStatus from "components/shared-components/JobStatus";
import DueDate from "components/shared-components/DueDate";
import PageTitle from "components/shared-components/PageTitle";
import { useGetAllShopsQuery } from "redux/api/shopsApi";

const { Text } = Typography;
const { useBreakpoint } = Grid;

const Jobs = () => {
  let dispatch = useDispatch();

  const app = useSelector((state) => state.app);
  const user = useSelector((state) => state.auth.user.info);

  let navigate = useNavigate();
  let location = useLocation();

  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("sm");

  const [selectedJob, setSelectedJob] = useState(null);
  const [searchInputValue, setSearchInputValue] = useState(
    new URLSearchParams(location.search).get("search") || ""
  );
  const [searchValue, setSearchValue] = useState(
    new URLSearchParams(location.search).get("search") || ""
  );
  const [showArchived, setShowArchived] = useState(
    new URLSearchParams(location.search).get("archived") || "false"
  );
  const [shopFilter, setShopFilter] = useState(
    new URLSearchParams(location.search).get("shop") || null
  );
  const [orderValue, setOrderValue] = useState(
    new URLSearchParams(location.search).get("order") || ""
  );
  const [pageNumber, setPageNumber] = useState(
    parseInt(new URLSearchParams(location.search).get("page")) || 1
  );

  const updateUrlParams = (params) => {
    const searchParams = new URLSearchParams(location.search);

    for (const key in params) {
      if (params[key]) {
        searchParams.set(key, params[key]);
      } else {
        searchParams.delete(key);
      }
    }

    navigate({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  const jobsQuery = useGetAllJobsQuery(
    {
      search: searchValue,
      shop: shopFilter,
      archived: showArchived,
      order: orderValue,
      page: pageNumber,
    },
    {
      pollingInterval: 30_000,
      refetchOnMountOrArgChange: true,
    }
  );

  const shopsQuery = useGetAllShopsQuery();

  const { data, isLoading } = jobsQuery;

  const getSoonestDueDate = (job) => {
    let dueDates = [job.dueDate];

    if (job.shopDueDate) {
      dueDates.push(job.shopDueDate);
    }

    if (job.lineItems.length > 0) {
      for (const lineItem of job.lineItems) {
        if (lineItem.lineItemShopDueDate) {
          dueDates.push(lineItem.lineItemShopDueDate);
        }
        if (lineItem.lineItemDueDate) {
          dueDates.push(lineItem.lineItemDueDate);
        }
      }
    }

    dueDates.sort();
    const soonest = dueDates[0];

    return soonest;
  };

  const tableColumns = [
    {
      key: "jobNumber",
      title: "Job #",
      width: 400,
      sorter: true,
      render: (record) => {
        return (
          <>
            <div className="d-flex flex-row align-items-center">
              <Link to={`/dashboard/jobs/${record._id}`}>
                <div className="mr-2">
                  <Badge
                    shape="square"
                    size="small"
                    count={
                      moment().diff(moment(record.createdAt), "days") < 2
                        ? "new"
                        : null
                    }
                    className="mr-3"
                  >
                    <Text
                      // mark
                      style={{
                        fontFamily: "Plus Jakarta Sans",
                        fontWeight: 800,
                        fontSize: "1rem",
                        color: "#0083ff",
                        lineHeight: "1.75rem",
                        textDecoration: "underline",
                      }}
                    >
                      {user?.role.canReadJobsFromAllShops
                        ? record.jobNumber
                        : record.jobNumber.slice(-3)}
                    </Text>
                  </Badge>

                  <br />
                  <span className="text-dark">{`${record.description}`}</span>
                </div>
              </Link>

              {!record?.files?.find((x) => x.type == "Purchase Order") ? (
                <div className="ml-auto">
                  <Tooltip
                    placement="left"
                    title={() => (
                      <>
                        <span>Missing:</span>
                        <br />
                        <strong>Purchase Order</strong>
                      </>
                    )}
                  >
                    <WarningFilled
                      style={{ color: "#ff9900", fontSize: "20px" }}
                    />
                  </Tooltip>
                </div>
              ) : null}
            </div>
            {record?.lineItems?.find(
              (x) => x?.subcontracting?.isSubcontracted == true
            ) ? (
              <Tag
                className="mr-0 mt-1"
                color={"geekblue"}
                style={{
                  padding: "2px 8px",
                  lineHeight: "24px",
                }}
              >
                Subcontracted
              </Tag>
            ) : null}
          </>
        );
      },
    },
    {
      key: "totalQuantity",
      title: "Qty",
      sorter: true,
      render: (_, record) => {
        const units = record.quantity
          ? record.quantity
          : record.lineItems.reduce(
              (prev, curr) =>
                prev +
                Number(
                  curr.deliverySchedule && curr.deliverySchedule.length > 0
                    ? curr.deliverySchedule.reduce(
                        (a, b) => a + Number(b.quantity),
                        0
                      )
                    : curr.lineItemQuantity
                ),
              0
            );

        return (
          <>
            <strong>{`${record.lineItems.length} parts`}</strong>{" "}
            <>{`(${units} ${units > 1 ? "Units" : "Unit"})`}</>
          </>
        );
      },
    },
    {
      key: "customer",
      title: "Customer",
      render: (record) => (
        <Link to={`/dashboard/customers/${record.customer._id}`}>
          {record.customer.customerCode ||
            record.customer.name ||
            `${record.customer.firstName} ${record.customer.lastName}`}
        </Link>
      ),
      sorter: true,
      hidden: !user.role.canReadCustomersFromOwnShop,
    },
    {
      key: "shop",
      title: "Shop",
      render: (record) => record.shop.name,
      sorter: true,
      hidden: !user.role.canReadJobsFromAllShops,
    },
    {
      key: "receivedOn",
      title: "Received",
      dataIndex: "receivedOn",
      responsive: ["xs"],
      render: (receivedOn) => (
        <Flex alignItems="center">{moment(receivedOn).format("MM/DD/YY")}</Flex>
      ),
      sorter: true,
    },
    {
      key: "dueDate",
      title: "Due",
      render: (record) => {
        return {
          props: {
            style: {
              ...utils.getDueDateTagColor(
                getSoonestDueDate(record),
                record?.progress
              ),
            },
          },
          children: (
            <DueDate getSoonestDueDate={getSoonestDueDate} job={record} />
          ),
        };
      },
      sorter: true,
      defaultSortOrder: "descend",
    },
    {
      title: "Progress",
      key: "progress",
      render: (record) => <JobStatus job={record} type="circle" />,
      sorter: true,
    },
    {
      key: "archived",
      title: "Archived",
      dataIndex: ["archived"],
      sorter: true,
      render: (archived) => (
        <Tag color={archived === true ? "default" : "blue"}>
          {archived === true ? "Archived" : "Active"}
        </Tag>
      ),
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    if (app.jobs.showUpdateJobDrawer === false) {
      setSelectedJob({});
    }
  }, [app.jobs.showUpdateJobDrawer]);

  useEffect(() => {
    updateUrlParams({
      search: searchValue,
      shop: shopFilter,
      archived: showArchived,
      order: orderValue,
      page: pageNumber,
    });
  }, [searchValue, shopFilter, showArchived, orderValue, pageNumber]);

  const handleSearch = () => {
    if (searchInputValue.trim() === "") {
      setSearchValue("");
      updateUrlParams({ search: null });
    } else {
      setSearchValue(searchInputValue);
      updateUrlParams({ search: searchInputValue });
    }
  };

  return (
    <div className="animate__animated animate__fadeIn">
      <PageTitle title="Jobs" />
      {isMobile ? (
        <MobileJobsList
          user={user}
          jobs={data?.data}
          dispatch={dispatch}
          setSearchValue={setSearchValue}
          setSelectedJob={setSelectedJob}
          getSoonestDueDate={getSoonestDueDate}
          showArchived={showArchived}
          setShowArchived={setShowArchived}
        />
      ) : (
        <Card>
          <Flex alignItems="start" justifyContent="between" mobileFlex={false}>
            <Flex className="mb-1" mobileFlex={false}>
              <div className="mr-md-3 mb-3">
                <Input.Search
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                  onChange={(e) => setSearchInputValue(e.currentTarget.value)}
                  onSearch={handleSearch}
                  value={searchInputValue}
                  disabled={isLoading}
                  style={{ minWidth: "400px" }}
                  onClear={handleSearch}
                  enterButton="Search"
                />
              </div>
              {user.role.canReadJobsFromAllShops ? (
                <div className="mr-md-1 mb-3">
                  <Form.Item>
                    <Select
                      placeholder="Shop"
                      options={shopsQuery?.data?.map((x) => {
                        return { value: x._id, label: x.name };
                      })}
                      prefix={<SearchOutlined />}
                      onChange={(value) => {
                        setShopFilter(value);
                      }}
                      value={shopFilter}
                      style={{ minWidth: "200px" }}
                      allowClear={true}
                      onClear={() => setShopFilter(null)}
                      disabled={isLoading || searchValue}
                    />
                  </Form.Item>
                </div>
              ) : null}
              {user.role.canReadArchivedJobs ? (
                <div className="mr-md-1 mb-3">
                  <Form.Item>
                    <Select
                      placeholder="Archived"
                      options={[
                        {
                          label: "Archived",
                          value: "true",
                        },
                        {
                          label: "Active",
                          value: "false",
                        },
                      ]}
                      prefix={<SearchOutlined />}
                      onChange={(value) => {
                        setShowArchived(value);
                      }}
                      value={showArchived}
                      style={{ minWidth: "200px" }}
                      allowClear={true}
                      onClear={() => setShowArchived(null)}
                      disabled={isLoading || searchValue}
                    />
                  </Form.Item>
                </div>
              ) : null}
            </Flex>
            <div>
              <Button
                onClick={() => {
                  navigate("/dashboard/jobs/add");
                }}
                type="primary"
                icon={<PlusOutlined />}
                block
                disabled={isLoading}
              >
                Add Job
              </Button>
            </div>
          </Flex>
          {/* <pre>{JSON.stringify(jobs)}</pre> */}
          <div className="table-responsive">
            <Table
              columns={tableColumns}
              dataSource={data?.data}
              pagination={{
                defaultPageSize: data?.metadata?.pageSize,
                current: data?.metadata?.page,
                total: data?.metadata?.totalCount,
                onChange: (page, pageSize) => {
                  setPageNumber(page);
                  updateUrlParams({ page });
                },
              }}
              rowKey="id"
              onChange={(pagination, filters, sorter) => {
                let param = undefined;

                if (sorter.order === "ascend") {
                  param = `${sorter.columnKey}`;
                } else if (sorter.order === "descend") {
                  param = `-${sorter.columnKey}`;
                } else if (sorter.order === undefined) {
                  param = undefined;
                }

                setOrderValue(param);
                updateUrlParams({ order: param });
              }}
              loading={isLoading}
            />
          </div>
        </Card>
      )}
    </div>
  );
};

export default Jobs;
