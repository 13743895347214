import React, { useEffect, useRef } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import { useDispatch, useSelector } from "react-redux";
import { onConfettiExplosion } from "redux/slices/theme";

const Confetti = () => {
  // Confetti explosion when job is shipped (you're welcome Russell :))
  const dispatch = useDispatch();
  const isExploding = useSelector((state) => state.theme.confetti);

  const confettiContainerRef = useRef();

  useEffect(() => {
    if (isExploding === true) {
      setTimeout(() => dispatch(onConfettiExplosion(false)), 4000);
    }
  }, [isExploding]);

  return (
    <div
      style={{
        position: "fixed",
        top: "33%",
        left: "50%",
        overflow: "visible",
        height: "200vh",
        zIndex: "99999",
      }}
    >
      <div ref={confettiContainerRef}>
        {isExploding && (
          <ConfettiExplosion
            {...{
              force: 0.6,
              duration: 5000,
              particleCount: 200,
              floorHeight: 1600,
              floorWidth: 1600,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Confetti;
