import React from "react";

const PageTitle = ({ title }) => {
  return (
    <h1 className="mb-3 ml-3" style={{ fontFamily: "Gotham Black", fontSize: "1.75rem" }}>
      {title}
    </h1>
  );
};

export default PageTitle;
