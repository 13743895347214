import React, { useEffect, useState } from "react";
import { Card, Table, Input, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

// Components
import { SearchOutlined } from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import utils from "utils";

// Redux
import PageTitle from "components/shared-components/PageTitle";
import { useGetCustomerRejectedPartsByShopQuery } from "redux/api/customerRejectedPartsApi";
import { showCustomerRejectedPartDrawer } from "redux/slices/jobs";
import CustomerRejectedPartDrawer from "./CustomerRejectedPartDrawer";
import Shops from "components/shared-components/Dropdowns/Shops";

const { Text } = Typography;

const CustomerRejectedParts = () => {
  let dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user.info);

  const [selectedItem, setSelectedItem] = useState({});
  const [selectedShop, setSelectedShop] = useState(user?.shop?._id);
  const [searchResults, setSearchResults] = useState([]);
  const [searchValue, setSearchValue] = useState(null);

  const app = useSelector((state) => state.app);

  const { data, isLoading, refetch } = useGetCustomerRejectedPartsByShopQuery(
    { shopId: user?.shop?._id },
    { skip: !user?.shop?._id, refetchOnMountOrArgChange: true }
  );

  const tableColumns = [
    {
      title: "Related Job",
      render: (record) => (
        <>
          <div
            className="mr-2"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedItem(record);
              dispatch(showCustomerRejectedPartDrawer());
            }}
          >
            <Text
              strong
              style={{
                fontSize: ".9rem",
                lineHeight: "1.75rem",
              }}
            >
              {`${record?.lineItem?.job?.jobNumber}`}
            </Text>
          </div>
        </>
      ),
    },
    {
      title: "Related Line Item",
      render: (record) => record?.lineItem?.lineItemDescription,
    },
    {
      title: "Shop",
      render: (record) => record?.lineItem?.job?.shop?.name,
      sorter: (a, b) =>
        a.lineItem.job.shop.name > b.lineItem.job.shop.name
          ? -1
          : b.lineItem.job.shop.name > a.lineItem.job.shop.name
          ? 1
          : 0,
      hidden: !user.role.canReadItemsFromAllShops,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Reject Date",
      render: (record) => moment(record.rejectDate).format("YYYY-MM-DD"),
      sorter: (a, b) =>
        a.rejectDate > b.rejectDate ? -1 : b.rejectDate > a.rejectDate ? 1 : 0,
      hidden: !user.role.canReadItemsFromAllShops,
    },
  ].filter((item) => !item.hidden);

  useEffect(() => {
    if (searchValue !== null && searchValue !== "") {
      const results = utils.wildCardSearch(data, searchValue);
      setSearchResults(results);
    }
  }, [searchValue]);

  useEffect(() => {
    if (app.items.showUpdateItemDrawer === false) {
      setSelectedItem({});
    }
  }, [app.items.showUpdateItemDrawer]);

  return (
    <>
      <PageTitle title="Customer Rejected Parts" />
      <CustomerRejectedPartDrawer id={selectedItem?._id} />
      <Card>
        <Flex alignItems="start" justifyContent="between" mobileFlex={false}>
          <Flex className="mb-1" mobileFlex={false}>
            <div className="mr-md-3 mb-3">
              <Input
                placeholder="Search"
                prefix={<SearchOutlined />}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </Flex>
          <div>
            <Shops onChange={setSelectedShop} defaultValue={selectedShop} />
          </div>
          {/* <div>
            <Button
              onClick={() => dispatch(showCustomerRejectedPartsDrawer())}
              type="primary"
              icon={<PlusOutlined />}
              block
            >
              Add Item
            </Button>
          </div> */}
        </Flex>
        <div className="table-responsive">
          <Table
            columns={tableColumns}
            loading={isLoading}
            dataSource={searchValue ? searchResults : data}
            pagination={{ defaultPageSize: 50 }}
            rowKey="_id"
          />
        </div>
      </Card>
    </>
  );
};

export default CustomerRejectedParts;
