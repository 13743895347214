import React from "react";
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP } from "constants/ThemeConstant";
import { APP_NAME, APP_NAME_SHORT } from "configs/AppConfig";
import { useSelector } from "react-redux";
import { Grid } from "antd";
import utils from "utils";

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  if (isMobile && !props.mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return "auto";
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  } else {
    return `${SIDE_NAV_WIDTH}px`;
  }
};

const getLogo = (props) => {
  const { navCollapsed, logoType } = props;
  if (logoType === "light") {
    if (navCollapsed) {
      return "/img/logo-sm-white.png";
    }
    return "/img/logo-white.png";
  }

  if (navCollapsed) {
    return "/img/logo-sm.png";
  }
  return "/img/logo.png";
};

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return "d-none";
  } else {
    return "logo";
  }
};

const Logo = (props) => {
  const user = useSelector((state) => state.auth.user.info);
  const { navCollapsed, navType } = props;

  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      // style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
    >
      {/* <img src={getLogo(props)} alt={`${APP_NAME} logo`}/> */}
      {/* {navCollapsed ? (
        <h3 className="mx-auto my-0">{utils.getNameInitial(user.shop.name)}</h3>
      ) : (
        <h1 className="d-block m-0 p-0" style={{ fontSize: "1.1rem" }}>
          {user.shop.name}
        </h1>
      )} */}
      <h1 className="d-block m-0 p-0" style={{ fontSize: "1.1rem" }}>
        {user.shop.name}
      </h1>
    </div>
  );
};

export default Logo;
