import { combineReducers } from "redux";
import { annualLLCsSlice } from "./annualLLCs";
import { customersSlice } from "./customers";
import { itemsSlice } from "./items";
import { jobsSlice } from "./jobs";
import { machinesSlice } from "./machines";
import { maintenancesSlice } from "./maintenances";
import { rolesSlice } from "./roles";
import { shopsSlice } from "./shops";
import { tasksSlice } from "./tasks";
import { usersSlice } from "./users";
import { materialRequestsSlice } from "./materialRequests";
import { projectsSlice } from "./projects";
import { schedulesSlice } from "./schedules";
import { uploadDocumentsSlice } from "./uploadDocuments";
import { productsSlice } from "./products";
import { quotesSlice } from "./quotes";

const reducers = combineReducers({
  quotes: quotesSlice.reducer,
  jobs: jobsSlice.reducer,
  projects: projectsSlice.reducer,
  items: itemsSlice.reducer,
  products: productsSlice.reducer,
  tasks: tasksSlice.reducer,
  shops: shopsSlice.reducer,
  customers: customersSlice.reducer,
  users: usersSlice.reducer,
  schedules: schedulesSlice.reducer,
  roles: rolesSlice.reducer,
  annualLLCs: annualLLCsSlice.reducer,
  machines: machinesSlice.reducer,
  maintenances: maintenancesSlice.reducer,
  materialRequests: materialRequestsSlice.reducer,
  uploadDocuments: uploadDocumentsSlice.reducer,
});

export default reducers;
