import React from "react";
import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { NAV_TYPE_SIDE } from "constants/ThemeConstant";
import MenuContent from "./MenuContent";
import { onMobileNavToggle } from "redux/slices/theme";
import Logo from "./Logo";
import Flex from "components/shared-components/Flex";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars-2";

export const MobileNav = ({ routeInfo, hideGroupTitle = true, localization = true }) => {
  const { mobileNav, sideNavTheme } = useSelector((state) => state.theme);

  const props = { sideNavTheme, routeInfo, hideGroupTitle, localization };

  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(onMobileNavToggle(false));
  };

  return (
    <Drawer
      placement="left"
      closable={false}
      onClose={onClose}
      visible={mobileNav}
      bodyStyle={{ padding: 5 }}
      width="378px"
    >
      <Flex flexDirection="column" className="h-100">
        <Flex justifyContent="between" alignItems="center">
          <Logo mobileLogo={true} />
          <div className="nav-close" onClick={() => onClose()}>
            <ArrowLeftOutlined />
          </div>
        </Flex>
        <div className="mobile-nav-menu">
          <MenuContent type={NAV_TYPE_SIDE} {...props} />
        </div>
      </Flex>
    </Drawer>
  );
};

export default MobileNav;
