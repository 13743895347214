import { CloudUploadOutlined, SendOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { Button, Col, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { showUploadDocumentsModal } from "redux/slices/uploadDocuments";
import UploadDocumentsModal from "./modals/UploadDocumentsModal";

const UploadDocuments = ({ navCollapsed }) => {
  const dispatch = useDispatch();
  return (
    <div style={{ position: "sticky", bottom: 0, left: 0 }}>
      <UploadDocumentsModal />
      <div
        style={{
          height: "30px",
          background: "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)",
        }}
      ></div>
      <div className="container p-2" style={{ background: "#fff" }}>
        <Row gutter={6}>
          <Col span={24}>
            <Button
              block
              size="large"
              type="primary"
              className="ant-btn-navy p-0"
              onClick={() => dispatch(showUploadDocumentsModal())}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                <FeatherIcon icon="send" size="20" strokeWidth="1.5" className="mr-2" />
                {!navCollapsed ? "Send to Office" : null}
              </div>
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UploadDocuments;
