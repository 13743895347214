import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiSlice } from "../slices/apiSlice";

// Define a service using a base URL and expected endpoints
export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signin: builder.mutation({
      query: (values) => ({
        url: "v1/auth/login",
        method: "POST",
        body: values,
      }),
    }),
    signout: builder.mutation({
      query: () => ({
        url: "v1/auth/logout",
        method: "POST",
        body: {},
      }),
    }),
    authStatus: builder.query({
      query: () => ({
        url: "v1/auth/",
        method: "GET",
      }),
    }),
    verifyGeoLocation: builder.mutation({
      query: (values) => ({
        url: "v1/auth/geoLocation",
        method: "POST",
        body: values,
      }),
    }),
    getClockedInStatus: builder.query({
      query: () => `v1/auth/clockedInStatus`,
    }),
    getMFASetupQRCode: builder.query({
      query: () => ({
        url: "v1/auth/mfa/setup/qrCode",
        method: "GET",
      }),
    }),
    verifyMFASetup: builder.mutation({
      query: (token) => ({
        url: "v1/auth/mfa/setup/verify",
        method: "POST",
        body: { token: token },
      }),
    }),
    validateMFAToken: builder.mutation({
      query: (body) => ({
        url: "v1/auth/mfa/validate",
        method: "POST",
        body: body,
      }),
    }),
  }),
});

export const {
  useSigninMutation,
  useSignoutMutation,
  useAuthStatusQuery,
  useVerifyGeoLocationMutation,
  useGetClockedInStatusQuery,
  useGetMFASetupQRCodeQuery,
  useVerifyMFASetupMutation,
  useValidateMFATokenMutation,
} = extendedApiSlice;
