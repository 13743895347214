import { createSlice } from "@reduxjs/toolkit";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from "../../constants/ThemeConstant";

const initialState = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_LIGHT,
  locale: "en",
  navType: NAV_TYPE_SIDE,
  topNavColor: "#3e82f7",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: "light",
  direction: DIR_LTR,
  confetti: false,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState: initialState,
  reducers: {
    toggleCollapsedNav(state, action) {
      state.navCollapsed = action.payload;
    },
    onNavStyleChange(state, action) {
      state.sideNavTheme = action.payload;
    },
    onLocaleChange(state, action) {
      state.locale = action.payload;
    },
    onNavTypeChange(state, action) {
      state.navType = action.payload;
    },
    onTopNavColorChange(state, action) {
      state.topNavColor = action.payload;
    },
    onHeaderNavColorChange(state, action) {
      state.headerNavColor = action.payload;
    },
    onMobileNavToggle(state, action) {
      state.mobileNav = action.payload;
    },
    onSwitchTheme(state, action) {
      state.currentTheme = action.payload;
    },
    onDirectionChange(state, action) {
      state.direction = action.payload;
    },
    onConfettiExplosion(state, action) {
      state.confetti = action.payload;
    },
  },
});

// Extract and export each action creator by name
export const {
  toggleCollapsedNav,
  onNavStyleChange,
  onLocaleChange,
  onNavTypeChange,
  onTopNavColorChange,
  onHeaderNavColorChange,
  onMobileNavToggle,
  onSwitchTheme,
  onDirectionChange,
  onConfettiExplosion,
} = themeSlice.actions;

// Export the reducer, either as a default or named export
export default themeSlice.reducer;
