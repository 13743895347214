import { Button, Card, Col, Result, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useAuthQBOQuery } from "redux/api/qboOAuth2Api";
import FeatherIcon from "feather-icons-react";
import { QuickbooksLogo } from "assets/svg/icon";

const ConnectQBO = () => {
  const [skip, setSkip] = useState(true);

  const authQBOQuery = useAuthQBOQuery({}, { skip: skip });

  useEffect(() => {
    if (authQBOQuery.isSuccess) {
      window.open(authQBOQuery?.data);
    }
  }, [authQBOQuery]);

  return (
    <div
      className="container d-flex flex-column justify-content-center vh-100 w-100"
      style={{ background: "#f9f9f9" }}
    >
      <Row justify="center" align="center">
        <Col>
          <Card className="d-flex justify-content-center p-4">
            <div style={{ width: "300px" }}>
              <QuickbooksLogo />
            </div>
            <h1 className="pt-2 pb-4">Start the integration by clicking the button below</h1>
            <Button
              size="large"
              type="primary"
              className="d-flex align-items-center"
              onClick={() => setSkip(false)}
              icon={<FeatherIcon icon="refresh-cw" size="18" className="mr-2" strokeWidth="3" />}
              loading={authQBOQuery.isLoading}
            >
              Connect To Quckbooks
            </Button>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const Success = () => {
  return (
    <Result
      status="success"
      title="Successfully Connected to Quickbooks Online!"
      subTitle="You have successfully integrated your app with your Quickbooks Online Account."
      extra={[
        <Button type="primary" htmlType="button">
          Go To Dashboard
        </Button>,
      ]}
    />
  );
};

export default ConnectQBO;
