import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quoteLineItemDrawerVisible: false,
};

export const quotesSlice = createSlice({
  name: "quotes",
  initialState,
  reducers: {
    showQuoteLineItemDrawer: (state) => {
      state.quoteLineItemDrawerVisible = true;
    },
    closeQuoteLineItemDrawer: (state) => {
      state.quoteLineItemDrawerVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showQuoteLineItemDrawer, closeQuoteLineItemDrawer } =
  quotesSlice.actions;

export default quotesSlice.reducer;
