import { configureStore } from "@reduxjs/toolkit";
import { rtkQueryErrorLogger } from "redux/middleware/rtkQueryErrorLogger";
import reducers from "redux/slices";
import { apiSlice } from "redux/slices/apiSlice";

const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

export default store;
