import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addUserDrawerVisible: false,
  updateUserDrawerVisible: false,
  passwordResetModalVisible: false,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    showAddUserDrawer: (state) => {
      state.addUserDrawerVisible = true;
    },
    closeAddUserDrawer: (state) => {
      state.addUserDrawerVisible = false;
    },
    showUpdateUserDrawer: (state) => {
      state.updateUserDrawerVisible = true;
    },
    closeUpdateUserDrawer: (state) => {
      state.updateUserDrawerVisible = false;
    },
    showPasswordResetModal: (state) => {
      state.passwordResetModalVisible = true;
    },
    closePasswordResetModal: (state) => {
      state.passwordResetModalVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showAddUserDrawer,
  closeAddUserDrawer,
  showUpdateUserDrawer,
  closeUpdateUserDrawer,
  showPasswordResetModal,
  closePasswordResetModal,
} = usersSlice.actions;

export default usersSlice.reducer;
