import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addProjectDrawerVisible: false,
  updateProjectDrawerVisible: false,
};

export const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    showAddProjectDrawer: (state) => {
      state.addProjectDrawerVisible = true;
    },
    closeAddProjectDrawer: (state) => {
      state.addProjectDrawerVisible = false;
    },
    showUpdateProjectDrawer: (state) => {
      state.updateProjectDrawerVisible = true;
    },
    closeUpdateProjectDrawer: (state) => {
      state.updateProjectDrawerVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showAddProjectDrawer,
  closeAddProjectDrawer,
  showUpdateProjectDrawer,
  closeUpdateProjectDrawer,
} = projectsSlice.actions;

export default projectsSlice.reducer;
