import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  columns: 0,
  ordered: [],
  modal: false,
  modalMode: "",
  currentListId: "",
  cardData: null,
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    showAddTaskDrawer: (state) => {
      state.addTaskDrawerVisible = true;
    },
    closeAddTaskDrawer: (state) => {
      state.addTaskDrawerVisible = false;
    },
    showUpdateTaskDrawer: (state) => {
      state.updateTaskDrawerVisible = true;
    },
    closeUpdateTaskDrawer: (state) => {
      state.updateTaskDrawerVisible = false;
    },
    updateOrdered: (state, action) => {
      state.ordered = action.payload;
    },
    updateColumns: (state, action) => {
      state.columns = action.payload;
    },
    updateModal: (state, action) => {
      state.modal = action.payload;
    },
    updateModalMode: (state, action) => {
      state.modalMode = action.payload;
    },
    updateCurrentListId: (state, action) => {
      state.currentListId = action.payload;
    },
    updateCardData: (state, action) => {
      state.cardData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showAddTaskDrawer,
  closeAddTaskDrawer,
  showUpdateTaskDrawer,
  closeUpdateTaskDrawer,
  updateOrdered,
  updateColumns,
  updateModal,
  updateModalMode,
  updateCurrentListId,
  updateCardData,
} = tasksSlice.actions;

export default tasksSlice.reducer;
