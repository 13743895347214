import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addScheduleDrawerVisible: false,
  updateScheduleDrawerVisible: false,
};

export const schedulesSlice = createSlice({
  name: "schedules",
  initialState,
  reducers: {
    showAddScheduleDrawer: (state) => {
      state.addScheduleDrawerVisible = true;
    },
    closeAddScheduleDrawer: (state) => {
      state.addScheduleDrawerVisible = false;
    },
    showUpdateScheduleDrawer: (state) => {
      state.updateScheduleDrawerVisible = true;
    },
    closeUpdateScheduleDrawer: (state) => {
      state.updateScheduleDrawerVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showAddScheduleDrawer,
  closeAddScheduleDrawer,
  showUpdateScheduleDrawer,
  closeUpdateScheduleDrawer,
} = schedulesSlice.actions;

export default schedulesSlice.reducer;
