import React, { useEffect, useState } from "react";
import {
  SettingOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Drawer, Menu, Button, Dropdown, Typography } from "antd";
import ThemeConfigurator from "./ThemeConfigurator";
import { DIR_RTL } from "constants/ThemeConstant";
import { useSignoutMutation } from "redux/api/authApi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Flex from "components/shared-components/Flex";
import NavNotification from "./NavNotification";
import { apiSlice } from "redux/slices/apiSlice";

const { Text } = Typography;

const NavPanel = () => {
  const { locale, direction } = useSelector((state) => state.theme);
  const [showDrawer, setShowDrawer] = useState(false);
  const navigate = useNavigate();
  const [signout, signoutResult] = useSignoutMutation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user.info);

  const signoutHandler = async () => {
    await signout();
  };

  useEffect(() => {
    if (signoutResult.isSuccess) {
      localStorage.removeItem("user");
      dispatch(apiSlice.util.resetApiState());
      navigate("/login");
      window.location.reload();
    }
  }, [signoutResult]);

  const menu = (
    <Menu mode="horizontal">
      <div className="d-block px-3 py-2">
        <Text className="font-size-md" strong>
          {user.isSharedAccount
            ? user.username
            : `${user.firstName} ${user.lastName}`}
        </Text>
        <br />
        <Text className="d-block mb-1">
          {user.isSharedAccount ? user.email : `${user.email}`}
        </Text>

        <Text>{`${user.shop.name}`}</Text>
        <br />
        <Text type="secondary">{`${user.role.name}`}</Text>
      </div>
      <Menu.Divider />
      <Menu.Item key="signout" onClick={() => signoutHandler()}>
        <LogoutOutlined /> Sign Out
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {/* <div className="align-self-center mr-3">
        <Button>
          <span
            style={{
              fontSize: "1.5rem",
              fontWeight: "900",
              marginRight: "0.5rem",
              lineHeight: ".75rem",
              verticalAlign: "middle",
            }}
          >
            {shop?.data?.daysOfWorkRemaining}
          </span>
          <span>Days of work</span>
        </Button>
      </div> */}

      <NavNotification />

      <Menu mode="horizontal">
        <Menu.Item key="notification" onClick={() => setShowDrawer(true)}>
          <SettingOutlined className="nav-icon mx-auto" type="bell" />
        </Menu.Item>
      </Menu>

      <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
        <Menu mode="horizontal">
          <Menu.Item key="notification">
            <UserOutlined className="nav-icon mx-auto" />
          </Menu.Item>
        </Menu>
      </Dropdown>

      <Drawer
        title="Theme Config"
        placement={direction === DIR_RTL ? "left" : "right"}
        width={350}
        onClose={() => setShowDrawer(false)}
        visible={showDrawer}
      >
        <ThemeConfigurator />
      </Drawer>
    </>
  );
};

export default NavPanel;
