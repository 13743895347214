import "./styles.css";
import { ArrowLeftOutlined, PrinterFilled } from "@ant-design/icons";
import { Alert, Button, Col, DatePicker, Form, Row, Typography } from "antd";
import Loading from "components/shared-components/Loading";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { useGetQuoteQuery } from "redux/api/quotesApi";
import { useGetAllMaterialsQuery } from "redux/api/materialsApi";
import { useGetAllSurfaceFinishesQuery } from "redux/api/surfaceFinishesApi";
import { useGetAllUsersQuery } from "redux/api/usersApi";
import Price from "components/shared-components/Price";
import pluralize from "pluralize";

const { Paragraph } = Typography;

const Quote = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const users = useGetAllUsersQuery();
  const quote = useGetQuoteQuery({ id: id });
  const materials = useGetAllMaterialsQuery();
  const surfaceFinishes = useGetAllSurfaceFinishesQuery();

  const [lineItems, setLineItems] = useState([]);
  const [customerName, setCustomerName] = useState(null);

  useEffect(() => {
    if (quote.isSuccess) {
      setLineItems(
        searchParams.get("lineItems")
          ? quote?.currentData?.lineItems?.filter((lineItem) =>
              searchParams
                .get("lineItems")
                .split(",")
                .includes(lineItem._id.toString())
            )
          : quote?.currentData?.lineItems
      );

      setCustomerName(
        quote?.currentData?.customer?.name
          ? quote?.currentData?.customer?.name
          : `${quote?.currentData?.customer?.firstName} ${quote?.currentData?.customer?.lastName}`
      );
    }
  }, [quote]);

  return (
    <>
      {quote?.isLoading ? (
        <Loading cover="content" />
      ) : quote?.isError ? (
        <div
          style={{
            display: "flex",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Alert
            type="error"
            message="Something went wrong :("
            description="Please try again later"
            showIcon={true}
            style={{ width: "300px" }}
          />
        </div>
      ) : (
        <>
          <div className="print-options">
            <Button
              onClick={() => navigate(`/dashboard/quotes/${id}`)}
              type="ghost"
            >
              <ArrowLeftOutlined /> BACK
            </Button>
            <Button onClick={() => window.print()} type="primary">
              <PrinterFilled /> PRINT
            </Button>
          </div>
          <div className="packing-slip">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                minHeight: "11in",
              }}
            >
              <div>
                <Row gutter={[16, 0]} style={{ marginBottom: "0.125in" }}>
                  <Col span={14}>
                    <div style={{ marginBottom: "0.375in" }}>
                      <h2
                        style={{
                          textTransform: "uppercase",
                          fontWeight: "900",
                          fontSize: "1.1rem",
                          marginTop: ".5rem",
                          color: "#333",
                        }}
                      >
                        {quote?.currentData?.shop?.name}
                      </h2>
                      <span>
                        {!quote?.currentData?.shop?.address?.street2
                          ? `${quote?.currentData?.shop?.address?.street}`
                          : `${quote?.currentData?.shop?.address?.street}, ${quote?.currentData?.shop?.address?.street2}`}
                      </span>
                      <br />
                      <span>{`${quote?.currentData?.shop?.address?.city}, ${quote?.currentData?.shop?.address?.state}, ${quote?.currentData?.shop?.address?.zip}`}</span>
                      <br />
                      <span>{`${quote?.currentData?.shop?.email}`}</span>
                      <br />
                      <span>{`${quote?.currentData?.shop?.phone}`}</span>
                    </div>

                    <div style={{ display: "flex", columnGap: "0.125in" }}>
                      {quote?.currentData?.deliveryType === "leadTime" ? (
                        <div style={{ width: "2.25in" }}>
                          <span className="d-block field-label">Lead Time</span>
                          <span
                            style={{
                              display: "block",
                              border: "1px solid #b5c4d6",
                              padding: "0.4rem 0.5rem",
                              minHeight: "0.35in",
                            }}
                          >
                            {pluralize(
                              quote?.currentData?.leadTime?.unit,
                              quote?.currentData?.leadTime?.value,
                              true
                            )}
                          </span>
                        </div>
                      ) : quote?.currentData?.deliveryType === "date" ? (
                        <div style={{ width: "2.25in" }}>
                          <span className="d-block field-label">
                            Delivery Date
                          </span>
                          <span
                            style={{
                              display: "block",
                              border: "1px solid #b5c4d6",
                              padding: "0.4rem 0.5rem",
                              minHeight: "0.35in",
                            }}
                          >
                            {moment(quote?.currentData?.deliveryDate).format(
                              "YYYY-MM-DD"
                            )}
                          </span>
                        </div>
                      ) : null}

                      <div style={{ width: "2.25in" }}>
                        <span className="d-block field-label">
                          Your RFQ Reference
                        </span>
                        <span
                          style={{
                            display: "block",
                            border: "1px solid #b5c4d6",
                            padding: "0.4rem 0.5rem",
                            minHeight: "0.35in",
                            wordWrap: "break-word",
                          }}
                        >
                          {quote?.currentData?.RFQReference}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col span={10}>
                    <h2
                      className="d-block text-right mb-4 font-weight-medium"
                      style={{
                        color: "#333",
                        lineHeight: "1",
                        fontSize: "1.75rem",
                      }}
                    >
                      Quotation
                    </h2>

                    <Row gutter={12} className="mb-3">
                      <Col span={12}>
                        <span className="d-block field-label">Date</span>
                        <span
                          style={{
                            display: "block",
                            border: "1px solid #b5c4d6",
                            padding: "0 0.5rem",
                            height: "0.35in",
                            lineHeight: "0.35in",
                          }}
                        >
                          {moment(quote?.currentData?.createdOn).format(
                            "YYYY-MM-DD"
                          )}
                        </span>
                      </Col>
                      <Col span={12}>
                        <span className="d-block field-label">
                          Quotation No.
                        </span>
                        <span
                          style={{
                            display: "block",
                            border: "1px solid #b5c4d6",
                            padding: "0 0.5rem",
                            height: "0.35in",
                            lineHeight: "0.35in",
                          }}
                        >
                          {quote?.currentData?.referenceNumber}
                        </span>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={24}>
                        <span className="d-block field-label">
                          Quote Prepared For
                        </span>
                        <div
                          style={{
                            display: "block",
                            border: "1px solid #b5c4d6",
                            padding: "1rem",
                          }}
                        >
                          {customerName}
                          <br />
                          {quote?.currentData?.contact ? (
                            <>
                              <span className="font-weight-semibold">
                                ATTN:{" "}
                              </span>
                              {`${quote?.currentData?.contact?.firstName} ${quote?.currentData?.contact?.lastName}`}
                            </>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Col span={24}>
                  <span className="d-block field-label">Notes</span>
                  <div
                    style={{
                      display: "block",
                      border: "1px solid #b5c4d6",
                      padding: "0.5rem 0.625rem",
                      minHeight: "0.35in",
                    }}
                  >
                    {quote?.currentData?.quoteNotes}
                  </div>
                </Col>
                <Row style={{ marginBottom: "0.25in" }}></Row>
                <table
                  width="100%"
                  cellpadding="6px"
                  style={{
                    marginBottom: "0.25in",
                    border: "1px solid #ccc",
                    borderCollapse: "collapse",
                  }}
                >
                  <th align="left">#</th>
                  <th align="left">Description</th>
                  <th align="left">Material</th>
                  <th align="left">Finish</th>
                  <th align="center">Qty</th>
                  <th align="right">Unit</th>
                  <th align="right">Total</th>
                  {lineItems.map((lineItem, index) => (
                    <tr>
                      <td style={{ padding: "0.375rem 6px" }}>{index + 1}</td>
                      <td style={{ padding: "0.375rem 6px" }}>
                        {lineItem?.description}
                      </td>
                      <td style={{ padding: "0.375rem 6px" }}>
                        {
                          materials?.currentData?.find(
                            (x) => x._id === lineItem?.material
                          )?.name
                        }
                      </td>
                      <td style={{ padding: "0.375rem 6px" }}>
                        {
                          surfaceFinishes?.currentData?.find(
                            (x) => x._id === lineItem?.surfaceFinish
                          )?.name
                        }
                      </td>
                      <td style={{ padding: "0.375rem 6px" }} align="center">
                        {lineItem?.quantity}
                      </td>
                      <td style={{ padding: "0.375rem 6px" }} align="right">
                        <Price value={lineItem?.unitPrice} />
                      </td>
                      <td style={{ padding: "0.375rem 6px" }} align="right">
                        <Price value={lineItem?.totalPrice} />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td
                      colSpan={7}
                      align="right"
                      style={{ padding: "0.5rem 6px" }}
                    >
                      <strong style={{ marginRight: "0.5rem" }}>Total:</strong>
                      <strong>
                        <Price value={quote?.currentData?.total} />
                      </strong>
                    </td>
                  </tr>
                </table>
              </div>
              <div style={{ fontSize: "10px", marginBottom: "0.25in" }}>
                <div style={{ fontSize: "13px", marginBottom: "0.25in" }}>
                  <strong>Quote Prepared By: </strong>
                  {`${quote?.currentData?.createdBy?.firstName} ${quote?.currentData?.createdBy?.lastName}`}
                </div>
                <div style={{ fontSize: "13px", marginBottom: "0.125in" }}>
                  <strong>Terms: {quote?.currentData?.terms}</strong>
                  <br />
                </div>
                <span>
                  Please Note: Price is subject to review at time of order based
                  on material costs. Accounts Overdue will incur a delinquency
                  charge of 1 1/2% per month of the amount due.
                </span>
                <br />
                <strong>Blanket Orders: </strong>Unless otherwise noted,
                invoices will reflect the price for the quantity shipped.
                <br />
                <strong>Samples:</strong> Unless noted, prices above do not
                reflect sample runs.
                <br />
                <strong>Certification:</strong> Requests of any additional
                documentation required will incur an additional charge.
                <br />
                <strong>Shipment/Billing Quantity:</strong> Orders/Quotes over
                199pcs will have a +/- 10% quantity tolerance.
                <br />
                <strong>
                  THIS QUOTATION MAY BE WITHDRAWN BY CENTER TOOL COMPANY IF NOT
                  ACCEPTED WITHIN 14 DAYS. QUOTE NOT VALID UNLESS SIGNED.
                </strong>
                <br />
              </div>
            </div>
            <div style={{ fontSize: "10px" }}>
              <p>
                BY SUBMITTING A PURCHASE ORDER, CUSTOMER AGREES TO, ACCEPTS, AND
                ACKNOWLEDGES ALL TERMS AND CONDITIONS SET FORTH HEREIN AND ON
                THE SUBSEQUENT PAGES.
              </p>
              <p>
                1. General Terms. All sales of goods (“goods”) provided by
                Center Tool Company (“CTC”) are made pursuant to CTC’s “Terms
                and Conditions”. CTC’s “Terms and Conditions” shall include all
                terms and conditions contained herein, as well as on all
                previous and subsequent pages. In the event of a conflict
                between CTC’s Terms and Conditions and the terms contained in a
                purchase order or other sign of acceptance, CTC’s Terms and
                Conditions shall govern. CTC’s Terms and Conditions, constitute
                an offer WHICH EXPRESSLY LIMIITS ACCEPTANCE TO CTC’S TERMS AND
                CONDITIONS. CTC will not be bound by the different terms and/or
                conditions contained in a Customer’s purchase order,
                acknowledgement or other documents. If the Customer’s purchase
                order, acknowledgement or other documents contain terms which
                are inconsistent with CTC’s Terms and Conditions, and such
                documents preceded CTC’s Terms and Conditions and Proposal, such
                documents shall be deemed to be a solicitation. Neither party
                may revoke, amend or waive CTC’s Terms and Conditions or any
                part thereof except by mutual written agreement signed by both
                parties. CTC’s Terms and Conditions shall inure to the benefit
                of and be binding upon the successors, assigns and legal
                representatives of the parties hereto. The Customer shall be
                solely responsible for payment of the goods described in the
                Proposal.
              </p>
              <p>
                2. Terms of Payment and Delivery. CTC shall issue an invoice to
                Customer upon shipment of the requested goods. Payments shall be
                made to CTC’s offices in Hampshire, Illinois.
              </p>
              <p>
                3. Shipping Terms. The goods shall be shipped FOB CTC’s location
                in Hampshire, Illinois.
              </p>
              <p>
                4. Scheduling and Cancellation. By submitting a purchase order
                and accepting CTC’s Terms and Conditions, Customer agrees to
                indemnify CTC for any costs expended for the goods, plus any
                expenses accrued by CTC in the event the delivery of the goods,
                if delivery of the goods is cancelled for any reason outside
                CTC’s direct control. Customer further agrees to indemnify CTC
                against all loss and expenses incurred by CTC by such
                cancellation or schedule change, including restocking charges.
              </p>
              <p>
                5. Limited Warranty, Warranty Claims, Limitations for Actions or
                Suits. CTC hereby expressly warrants its goods against defects
                in workmanship for a period of one (1) year from date of
                delivery or installation, whichever comes first. In no event
                shall CTC be liable for any warranty claim arising from:
                excessive wear and tear; vandalism; usage in a manner
                inconsistent with the normal or intended usage of such goods;
                misuse; neglect; improper maintenance; work or omission by any
                other person or party, including but not limited to contractors,
                tradesman, installers, architects, or others not under the
                direct supervision and control of CTC. Written notice of a
                warranty claim must be made to CTC’s Hampshire, Illinois office
                within said period, or within 14 days of discovery of the
                alleged defect, whichever term is shorter. Any action or suit
                against CTC arising out of or relating to this Limited Warranty
                must be commenced within one (1) year after the cause of action
                has accrued.
              </p>
              <p>
                6. LIMITATION OF LIABILITY AND DISCLAIMER. NOTWITHSTANDING ANY
                PROVISION IN CTC’S TERMS AND CONDITIONS TO THE CONTRARY, THE
                WARRANTY CONTAINED IN SECTION 4 AS LIMITED HEREIN AND THE
                EXPRESS WARRANTY OF GOOD TITLE, ARE THE ONLY WARRANTIES EXTENDED
                OR OFFERED BY CTC IN CONNETION WITH THE GOODS OUTLINED IN THE
                PROPOSAL, AND ARE IN LIEU OF ALL OTHER WARRANTIES, EXPRESS OR
                IMPLED, INCLUDING WARRANTIES OF MERCHANTABILITY, HABITIABILITY
                OR FITNESS FOR A PARTICULAR PURPOSE. CTC’S OBLIGATIONS UPON
                BREACH OF WARRANTY OR OTHER PROVISION OF CTC’S TERMS AND
                CONDITIONS OR PROPOSAL OR AS IMPOSED BY LAW SHALL BE LIMITED TO
                THE REPAIR OR REPLACEMENT OF THE GOODS PROVIDED BY CTC. IN NO
                EVENT SHALL CTC HAVE ANY LIABILITY WHATSOEVER FOR PAYMENT OF ANY
                CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL OR TORT DAMAGES OF
                ANY KIND EVEN IF A PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF
                SUCH DAMAGES. NO ALLOWANCE SHALL BE MADE FOR ANY EXPENSES
                INCURRED BY THE CUSTOMER (OR THEIR SUCCESSORS, ASSIGNS AND
                BENEFICIARIES) IN REPAIRING OR REPLACING ANY OTHER PORTION OF
                ANY BUILDING, STRUCTURE OR THE PERSONAL PROPERTY CONTAINED
                THEREIN, SAVE FOR THE GOODS OUTLINED IN THE PROPOSAL. CTC’S
                TOTAL LIABILITY HEREUNDER SHALL NOT EXCEED THE PRICES QUOTED IN
                THE PROPOSAL, NET OF ANY TRANSPORTATION AND SHIPPING CHARGES. IN
                THE EVENT OF A BREACH OF THE WARRANTY OR ANY OTHER PROVISION
                HEREOF BY CTC, CTC SHALL HAVE THE OPTION TO REPLACE THE
                DEFECTIVE GOODS OR REFUND THE MONIES PAID BY THE CUSTOMER.
              </p>
              <p>
                7. Indemnification. Customer shall defend, indemnify and hold
                harmless CTC, its affiliates, successors or assigns and their
                respective directors, officers, shareholders and employees
                (collectively, “Indemnitees”) against any and all loss, injury,
                death, damage, liability, claim, deficiency, action, judgment,
                interest, award, penalty, fine, cost or expense, including
                reasonable attorney and professional fees and costs, and the
                cost of enforcing any right to indemnification hereunder and the
                cost of pursuing any insurance providers (collectively,
                “Losses”) arising out of or occurring in connection with (a) the
                goods purchased from CTC, (b) acts, omissions or failures to act
                by CTC, its subcontractors, agents or employees, in connection
                with the performance or non-performance of CTC’s Terms and
                Conditions, or (c) or Customer’s negligence, willful misconduct
                or breach of the Terms. This paragraph shall not apply to Losses
                due to CTC’s gross negligence.
              </p>
              <p>
                8. Invalidity. If any provision of CTC’s Terms and Conditions
                are held invalid or unenforceable, then such provision shall be
                deemed to be modified to the extent and in the manner necessary
                to render it valid and enforceable, or if the provision cannot
                be so modified it shall be deemed stricken from CTC’s Terms and
                Conditions and not be held to affect any other provision hereof.
                CTC’s Terms and Conditions shall be construed and enforced to
                the maximum extent permitted by law.
              </p>
              <p>
                9. Waiver. No waiver of a violation of CTC’s Terms and
                Conditions shall constitute waiver of CTC’s Terms and
                Conditions. Any failure or delay on the part of either party to
                exercise any remedy or right under CTC’s Terms and Conditions
                shall not operate as a waiver. The failure of either party to
                require performance of any of the terms, covenants, or
                provisions of CTC’s Terms and Conditions by the other party
                shall not constitute a waiver of any rights.
              </p>
              <p>
                10. Attorney’s Fees and Costs. In the event any legal action or
                other proceeding is brought by CTC for the enforcement of CTC’s
                Terms and Conditions, CTC shall be entitled to recover
                reasonable attorney’s fees and all other costs incurred in that
                action or proceeding, in addition to any other relief to which
                it may be entitled.
              </p>
              <p>
                11. Choice of Law and Venue. CTC’s Terms and Conditions and
                performance hereunder shall be governed and construed in
                accordance with the laws of the State of Illinois of the United
                States of America. The parties agree to submit themselves to the
                jurisdiction of the federal or state courts with jurisdiction
                for Kane County, Illinois. Any and all proceedings relating to
                the subject matter hereof shall be maintained in the federal or
                state courts with jurisdiction in Kane County, Illinois, which
                courts shall have exclusive jurisdiction for such purpose.
              </p>
              <p>
                12. Construction. The language used in CTC’s Terms and
                Conditions shall be deemed to be the language chosen by both of
                the parties hereto to express their mutual intent, and no rule
                of strict construction shall be applied against any party
                hereto. The parties hereto understand, agree and acknowledge
                that, in any controversy, dispute or contest over the meaning,
                interpretation, validity or enforceability of CTC’s Terms and
                Conditions or any of its terms or conditions, there shall be no
                inference, presumption or conclusion drawn whatsoever against
                any party hereto by virtue of that party or its agent having
                drafted CTC’s Terms and Conditions or any portion thereof.
              </p>
              <p>
                13. Survival. The provisions of CTC’s Terms and Conditions,
                which by their nature extend beyond the termination of the
                agreement, including without limitation the indemnification and
                the limited warranty, will survive termination of this agreement
                for any reason.
              </p>
              <p>
                14. Final Agreement. CTC’s Terms and Conditions, and any
                Exhibits which may be referenced therein contain the entire
                understanding of the parties concerning the subject matter
                hereof and supersede any and all prior understandings,
                agreements or representations, either oral or in writing,
                between the parties hereto.
              </p>
              <p>
                15. Force Majeure. No party shall be liable to the other party,
                nor be deemed to have defaulted under or breached, or for any
                delay or failure in performing its obligations to the extent
                that such delay or failure is caused by Force Majeure except for
                any obligations to make payments to the other party hereunder.
                “Force Majeure” is an event or circumstance that is beyond the
                reasonable control of a party, without such party’s fault or
                negligence, and which by its nature could not be reasonably
                foreseen, prevented, or avoided by such party. Force Majeure
                events include but are not limited to: fires, explosions,
                floods, tornadoes, pandemics, epidemics, acts of God, civil
                disturbance, war, riots, sabotage, terrorism, restraints or
                injunctions issued by a court or other governmental entity,
                executive orders, or other government acts or regulations, or
                strikes or labor disputes. Economic hardship or changes in
                market conditions are not considered Force Majeure events.
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Quote;
