import React from "react";
import { Layout } from "antd";
import { connect, useSelector } from "react-redux";
import { SIDE_NAV_WIDTH, SIDE_NAV_DARK, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import MenuContent from "./MenuContent";
import ClockInOut from "./ClockInOut";
import Scrollbars from "react-custom-scrollbars-2";

const { Sider } = Layout;

export const SideNav = ({
  navCollapsed,
  sideNavTheme,
  routeInfo,
  hideGroupTitle,
  localization = true,
}) => {
  const props = { sideNavTheme, routeInfo, hideGroupTitle, localization };
  const user = useSelector((state) => state.auth.user.info);

  return (
    <Sider
      className={`side-nav ${sideNavTheme === SIDE_NAV_DARK ? "side-nav-dark" : ""}`}
      width={SIDE_NAV_WIDTH}
      collapsed={navCollapsed}
      breakpoint="xs"
    >
      <MenuContent type={NAV_TYPE_SIDE} {...props} />
    </Sider>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme } = theme;
  return { navCollapsed, sideNavTheme };
};

export default connect(mapStateToProps)(SideNav);
