import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploadDocumentsModalVisible: false,
};

export const uploadDocumentsSlice = createSlice({
  name: "uploadDocuments",
  initialState,
  reducers: {
    showUploadDocumentsModal: (state) => {
      state.uploadDocumentsModalVisible = true;
    },
    closeUploadDocumentsModal: (state) => {
      state.uploadDocumentsModalVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showUploadDocumentsModal, closeUploadDocumentsModal } = uploadDocumentsSlice.actions;

export default uploadDocumentsSlice.reducer;
