import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  addCustomerDrawerVisible: false,
  updateCustomerDrawerVisible: false,
  addAddressToCustomerDrawerVisible: false,
};

export const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    showAddCustomerDrawer: (state) => {
      state.addCustomerDrawerVisible = true;
    },
    closeAddCustomerDrawer: (state) => {
      state.addCustomerDrawerVisible = false;
    },
    showUpdateCustomerDrawer: (state) => {
      state.updateCustomerDrawerVisible = true;
    },
    closeUpdateCustomerDrawer: (state) => {
      state.updateCustomerDrawerVisible = false;
    },
    showAddAddressToCustomerDrawer: (state) => {
      state.addAddressToCustomerDrawerVisible = true;
    },
    closeAddAddressToCustomerDrawer: (state) => {
      state.addAddressToCustomerDrawerVisible = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  showAddCustomerDrawer,
  closeAddCustomerDrawer,
  showUpdateCustomerDrawer,
  closeUpdateCustomerDrawer,
  showAddAddressToCustomerDrawer,
  closeAddAddressToCustomerDrawer,
} = customersSlice.actions;

export default customersSlice.reducer;
